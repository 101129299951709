const styles = {
  cardContainer: {
    backgroundColor: 'gray.backgroundLightVariant2',
    borderRadius: '20px',
    border: '1px solid gray',
    borderColor: 'gray.200',
    hr: {
      borderColor: '#fefefe',
      opacity: 0.15,
      mt: 1.5,
      mb: 0.25,
      maxHeight: 146
    },
    ':hover': {
      cursor: 'pointer',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)'
    }
  },
  cardContainerViewMode: {
    backgroundColor: 'gray.backgroundLightVariant2',
    borderRadius: '20px',
    border: '1px solid gray',
    borderColor: 'gray.200',
    hr: {
      borderColor: '#fefefe',
      opacity: 0.15,
      mt: 1.5,
      mb: 0.25,
      maxHeight: 146
    }
  },
  buttonStackViewMode: { mt: '8px', mb: '5px' },
  overflowEllipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  divider: { height: '16px', borderColor: '#bdbdbd' },
  verticalDivider: { height: '16px', borderColor: 'gray.300', mx: 1 },
  labelChip: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  },
  dropdownContainer: {
    cursor: 'pointer',
    ml: 1,
    mr: 1,
    pl: 1,
    pr: 1,
    maxHeight: 32,
    borderRadius: 3,
    color: 'primary.500',
    backgroundColor: 'inherit'
  },
  activeDropdownContainer: {
    maxHeight: '24px',
    borderRadius: '15px',
    padding: '0 4px',
    margin: 'auto 8px',
    color: 'white.main',
    height: '24px',
    backgroundColor: 'primary.550'
  },
  visualizeButton: {
    bgcolor: 'primary.650',

    minWidth: 98,

    justifyContent: 'space-evenly',

    height: 30,

    border: 1,

    m: 1,

    borderColor: 'primary.650',

    p: '4px 16px 4px 16px',

    borderRadius: '16px',

    borderStyle: 'solid',

    textTransform: 'capitalize',

    fontWeight: '700',

    fontSize: '12px',

    cursor: 'pointer',

    alignSelf: 'flex-end',

    color: 'white.main',

    '&:hover': {
      borderColor: 'gray.lightVariant3',

      boxShadow: 'none',

      bgcolor: 'white.main',

      color: 'primary.700'
    },

    '&:disabled': {
      border: 'none'
    }
  },
  disabledResultsFor: {
    pointerEvents: 'none',
    opacity: 0.5
  },
  cardCheckbox: {
    mr: 1.5,
    ml: -1.4,
    '& svg': { fontSize: 18 },
    '&.Mui-checked': {
      color: 'primary.700'
    }
  },
  cardGroupTitle: {
    fontSize: 14,
    pt: 1.5,
    pb: 1.5,
    color: 'black.lightVariant4',
    maxHeight: 48,
    maxWidth: '60%',
    fontWeight: 600,
    lineHeight: '100%'
  },
  filledIcons: { width: '16px', height: '16px', color: 'primary.600' },
  viewToggleButtonBox: { mx: '8px' }
};

export default styles;
