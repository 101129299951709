import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { Header } from '../interface';
import notificationsStyles from '../../Notifications/styles/Notifications.styles';

const HeaderTabs = ({
  header,
  selectedTab,
  setSelectedTab,
  handleTabClickAction
}: {
  header: Header[];
  selectedTab: string;
  setSelectedTab: any;
  handleTabClickAction?: Function;
}) => {
  const history = useHistory();

  const handleChange = (key: string) => {
    setSelectedTab(key);
    // add query params to the url
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('tab', key);
    history.push(currentUrl.pathname + currentUrl.search);
    handleTabClickAction?.();
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={(event: React.SyntheticEvent, val) => handleChange(val)}
      variant='scrollable'
      scrollButtons='auto'
      sx={notificationsStyles.tabContainer}
      aria-label='scrollable auto tabs example'>
      {header.map((type: any, index: number) => (
        <Tab
          key={type.key}
          label={type.label}
          value={type.key}
          sx={{
            ...notificationsStyles.tabs,
            fontWeight: selectedTab === type.key ? 700 : 'normal',
            color: (theme: any) =>
              selectedTab === type.key
                ? `${theme.palette.primary.darkVariant2} !important`
                : `${theme.palette.gray.darkVariant1} !important`,
            backgroundColor: (theme: any) =>
              selectedTab === type.key ? theme.palette.primary.lightVariant1 : 'transparent',
            '&:hover': {
              backgroundColor: (theme: any) => theme.palette.primary.backgroundLight,
              borderColor: (theme: any) => theme.palette.primary.main
            },
            borderColor: (theme: any) =>
              selectedTab === type.key
                ? theme.palette.primary.backgroundLight
                : theme.palette.gray.lightVariant8,
            ml: index === 0 ? 1.6 : 0.5
          }}
        />
      ))}
    </Tabs>
  );
};

HeaderTabs.defaultProps = {
  handleTabClickAction: () => {}
};

export default HeaderTabs;
