import { SOURCE_MENU_ITEMS } from '../../pages/Home/const';

export const ALLOWED_SOURCE_APPLICATIONS = SOURCE_MENU_ITEMS.map(item =>
  item.module
    .filter(module => module?.canSubscribeSource && module?.subscriptionKey)
    .map(module => module.subscriptionKey)
).flat();

export const ALLOWED_DOCUMENT_SEARCH_SOURCES = SOURCE_MENU_ITEMS.map(item =>
  item.module.filter(module => module?.canSubscribeDocSearch).map(module => module.value)
).flat();
