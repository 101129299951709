import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// component
import DocumentListing from '../../../components/Dialogs/DocumentListing';

// utils
import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import getDateString from '../../../utils/getDateString';
// api
import { getOrphanDesignation } from '../../../api/pages/OrphanDesignations';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { FolderIcon } from '../../../assets/svgs/Icons';

const GridCellDocuments: React.FC<any> = ({ data: rowData }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<Array<any>>([]);

  const getData = async () => {
    const res = await getOrphanDesignation(rowData.designation_number);
    setData(res?.data?.body?.documents ?? []);
  };

  const handleOpenPopup = async (e: any) => {
    e.preventDefault();
    await getData();
    setOpen(true);
  };

  const documents: DocumentsGroup[] = useMemo(() => {
    const docs: DocumentItem[] = data
      ?.filter((item: any) => item.doc_id !== 0)
      ?.map((item: any) => {
        return {
          title: item.title?.split('.pdf')[0]?.replaceAll('_', ' ')?.replaceAll('-', ' '),
          title_link: item.s3_url
        };
      });

    const group: DocumentsGroup = {
      id: 'documents',
      categoryTitle: 'Documents',
      documents: docs
    };

    return [group];
  }, [data]);

  const chatRIAMetadata = useMemo(() => {
    return {
      'document-title': rowData?.generic_name ?? '',
      'approval-date': rowData?.decision_date ?? '',
      designation_number: rowData?.designation_number ?? '',
      _category: rowData?.latest_submission_stage_type ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    return [rowData?.decision_date, rowData?.designation_number].filter(
      (title: string) => !!title && title !== 'NA'
    );
  }, [data]);

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {rowData?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>

      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={rowData?.decision_date ? getDateString(rowData?.decision_date) : rowData?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source='ema_orphan'
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
          allowComparison={false}
        />
      )}
    </Box>
  );
};

const DocumentHistory = ({ row }: any) => {
  return <GridCellDocuments data={row} />;
};

export default DocumentHistory;
