/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';

// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// styles
import styles from '../../styles/Summary.styles';

// images
import Cross from '../../../../Images/Adcomm/cross.svg';
import Check from '../../../../Images/Adcomm/check.svg';
import Info from '../../../../Images/Adcomm/info.svg';
import TBD from '../../../../Images/Adcomm/tbd.svg';

// component
import { CustomProgressBar } from '../../../../components/CustomComponents/ProgressBar';
import DocumentIcon from '../../../../Images/documentsIcon.svg';
import SummarySkeleton from '../Skeleton/SummarySkeleton';
import { DocumentsGroup } from '../../../../components/Dialogs/DocumentListing/types';
import DocumentListing from '../../../../components/Dialogs/DocumentListing';
import { CENTER_MAPPING } from '../../constant';
import { formatMeetingData } from '../../utils/common';
import MeetingInfoChipCard from '../MeetingInfoChipCard';

const acmIcons: { [key: string]: any } = {
  Yes: <img alt='Check' src={Check} style={styles.icons} />,
  NA: <img alt='InfoLogo' src={Info} style={styles.icons} />,
  TBD: <img alt='TBDLogo' src={TBD} style={styles.icons} />,
  No: <img alt='CrossLogo' src={Cross} style={styles.icons} />
};

interface ResourceItemProps {
  name: string;
  link: string;
}

const Summary: React.FC<any> = ({
  isLoading,
  summaryData,
  resourceData,
  selectedMeeting,
  errorMessage
}) => {
  const [openDialog, setOpendialog] = useState<boolean>(false);

  const getDocumentTitle = useMemo(
    () => (name: string) => {
      const content: any = {};
      const title =
        selectedMeeting?.center === 'CDRH'
          ? summaryData?.info?.device_name
          : summaryData?.info?.drug_name;
      if (selectedMeeting?.center === 'CDER') {
        content.active_ingredient = title && title.length !== 0 ? title[0] : 'UNKNOWN';
      }
      if (selectedMeeting?.center === 'CDRH') {
        content.device_name = title && title.length !== 0 ? title[0] : 'UNKNOWN';
      }
      if (selectedMeeting?.center.trim() === 'CBER') {
        content['document-title'] = name;
      }

      return content;
    },
    [selectedMeeting, summaryData]
  );

  const documents: DocumentsGroup[] = useMemo(() => {
    const groups: DocumentsGroup[] = [];
    if (resourceData?.resources) {
      Object.entries(resourceData?.resources)?.forEach(([key, value]: any) => {
        const docs = value?.map((item: ResourceItemProps) => {
          const data = {
            title: item?.name,
            title_link: item?.link,
            chatRiaMetadata: {
              category: key,
              adcom_meeting_start_date: selectedMeeting?.date,
              meeting_id: selectedMeeting?.meeting_id
            }
          };
          data.chatRiaMetadata = { ...data.chatRiaMetadata, ...getDocumentTitle(item?.name) };
          return data;
        });

        if (docs?.length) {
          groups.push({
            id: key,
            categoryTitle: key,
            documents: docs
          });
        }
      });
    }

    return groups;
  }, [resourceData, selectedMeeting?.date]);

  const metadata = useMemo(
    () => [selectedMeeting?.center, selectedMeeting?.applicant],
    [selectedMeeting?.center, selectedMeeting?.applicant]
  );

  const handleOpenPopup = (e: React.SyntheticEvent, toOpen: boolean) => {
    e.preventDefault();
    if (!toOpen) {
      setOpendialog(true);
    }
  };

  if (isLoading || Object.keys(summaryData).length === 0) {
    return <SummarySkeleton />;
  }

  const drugsOrDevices =
    selectedMeeting.center === 'CDRH' ? summaryData.info.device_name : summaryData.info.drug_name;

  return (
    <Box flexDirection='column' justifyContent='space-evenly' height='inherit'>
      <Box maxHeight='35%' display='flex'>
        <Grid
          item
          lg={6}
          xs={6}
          p={2}
          sx={{ width: '50%', maxheight: '30%', marginTop: 1.5, overflow: 'auto' }}>
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <Typography sx={styles.key}>Committee:</Typography>
            </Grid>
            <Grid item xs={8}>
              {selectedMeeting ? (
                <Typography sx={styles.value}>{selectedMeeting?.name}</Typography>
              ) : (
                <Typography sx={styles.value}>Not Applicable</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <Typography sx={styles.key}>
                {selectedMeeting.center === 'CDRH' ? 'Device Name(s):' : 'Drug Name(s):'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {drugsOrDevices?.length ? (
                drugsOrDevices.map((name: string) => (
                  <Typography key={name} sx={styles.drugName}>
                    {name}
                  </Typography>
                ))
              ) : (
                <Typography sx={styles.drugName}>Not Applicable</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <Typography sx={styles.key}>Applicant Name:</Typography>
            </Grid>
            <Grid item xs={8}>
              {summaryData.info.applicant_name?.length ? (
                summaryData.info.applicant_name.map((item: string) => (
                  <Typography key={item} sx={styles.value}>
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography sx={styles.value}>Not Applicable</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <Typography sx={styles.key}>
                {selectedMeeting.center === 'CDRH'
                  ? 'Device Number:'
                  : selectedMeeting.center === 'CDER'
                  ? 'NDA/BLA Number:'
                  : 'Application Number:'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {summaryData.info.appl_no?.length > 0 ? (
                summaryData.info.appl_no.map((item: string, index: number) => (
                  <Typography key={item} sx={styles.value}>
                    {selectedMeeting.center === 'CDER' && summaryData.info.appl_type[index]}
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography sx={styles.value}>Not Applicable</Typography>
              )}
            </Grid>
          </Grid>
          {selectedMeeting?.center === 'CBER' && (
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Typography sx={styles.key}>Application Type:</Typography>
              </Grid>
              <Grid item xs={8}>
                {summaryData.info.appl_type?.length > 0 ? (
                  summaryData.info.appl_type.map((item: string) => (
                    <Typography key={item} sx={styles.value}>
                      {item}
                    </Typography>
                  ))
                ) : (
                  <Typography sx={styles.value}>Not Applicable</Typography>
                )}
              </Grid>
            </Grid>
          )}
          {(selectedMeeting?.center === 'CDER' || selectedMeeting?.center === 'CDRH') && (
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Typography sx={styles.key}>Approval Pathway:</Typography>
              </Grid>
              <Grid item xs={8}>
                {summaryData.info.approval_pathway?.length > 0 ? (
                  summaryData.info.approval_pathway.map((item: string) => (
                    <Typography key={item} sx={styles.value}>
                      {item}
                    </Typography>
                  ))
                ) : (
                  <Typography sx={styles.value}>Not Applicable</Typography>
                )}
              </Grid>
            </Grid>
          )}
          {(selectedMeeting?.center === 'CDRH' || selectedMeeting?.center === 'CBER') && (
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Typography sx={styles.key}>Topic:</Typography>
              </Grid>
              <Grid item xs={8}>
                {summaryData.info.topic?.length > 0 ? (
                  summaryData.info.topic.map((item: string) => (
                    <Typography key={item} sx={styles.value}>
                      {item}
                    </Typography>
                  ))
                ) : (
                  <Typography sx={styles.value}>Not Applicable</Typography>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <Typography sx={styles.key}>Date of Meeting:</Typography>
            </Grid>
            <Grid item xs={8}>
              {summaryData.info.date_of_meeting ? (
                <Typography sx={styles.value}>{summaryData.info.date_of_meeting}</Typography>
              ) : (
                <Typography sx={styles.value}>Not Applicable</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={4}>
              <Typography sx={styles.key}>Resources:</Typography>
            </Grid>
            <Grid item xs={8} id='adcomm-resource'>
              <Tooltip
                title={
                  Object.keys(resourceData?.resources)?.length === 0 ? 'No Resources Available' : ''
                }
                placement='bottom'>
                <Box
                  sx={styles.objective}
                  onClick={(e: React.SyntheticEvent) =>
                    handleOpenPopup(e, Object.keys(resourceData?.resources)?.length === 0)
                  }>
                  <Stack display='flex' flexDirection='row'>
                    {resourceData && resourceData?.document_count ? (
                      <Typography sx={styles.resource}>
                        {`${resourceData.document_count} Documents`}&nbsp;
                      </Typography>
                    ) : (
                      <Typography sx={styles.value}>{`0 Documents `}&nbsp;</Typography>
                    )}
                    <img src={DocumentIcon} alt='documentLogo' />
                  </Stack>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={6} p={2} sx={{ width: '50%', maxHeight: '30%' }}>
          <Grid item xs={12} maxHeight='10%'>
            <Typography sx={styles.textGreen}>Meeting Objective:</Typography>
          </Grid>
          <Grid item xs={12} maxHeight='90%' mt={1} overflow='auto'>
            {summaryData.info.meeting_objective ? (
              <Typography sx={styles.key}>{summaryData.info.meeting_objective}</Typography>
            ) : (
              <Typography sx={styles.value}>Not Applicable</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      {Object.keys(summaryData.questions).length !== 0 ? (
        <Box height='calc(100% - 35% - 7vh)'>
          <Box display='flex' justifyContent='center' height='20%'>
            <Grid item p={2} xs={12} container>
              <Grid item xs={6} p={2} container display='flex' flexDirection='column'>
                <Typography sx={styles.textHeading}>
                  Total Number of Questions:&nbsp;
                  <Typography sx={styles.textHeadingBold}>
                    {summaryData.questions.total_number_of_questions}&nbsp;
                  </Typography>
                </Typography>
                <Typography sx={styles.textHeading}>
                  Type of Questions:&nbsp;
                  <Typography sx={styles.textHeadingBold}>
                    {summaryData.questions.type_of_questions}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.heading}>Pre ACM</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.heading}>Post ACM</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={styles.heading}>ACM Question Response Summary</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display='flex' m={2} justifyContent='center' height='inherit'>
            <Grid container height='100%' sx={styles.row}>
              <Grid item xs={12} container>
                {summaryData.questions.questions_list.map(
                  (item: { [key: string]: string | any }, index: number) => {
                    return (
                      <Grid key={item.question} container xs={12} p={1}>
                        <Grid item xs={6} display='flex'>
                          <Typography pr={1} pl={1} sx={styles.textGreen}>
                            Q{index + 1}
                          </Typography>
                          <Tooltip title={item.question}>
                            <Typography pr={3} sx={styles.text}>
                              {item.question}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={2} p={2}>
                          <Tooltip title={item.pre_acm}>{acmIcons[item.post_acm]}</Tooltip>
                        </Grid>
                        <Grid item xs={2} p={2}>
                          <Tooltip title={item.post_acm}>{acmIcons[item.post_acm]}</Tooltip>
                        </Grid>
                        <Grid key={item.response_summary} mt={2} item xs={2}>
                          <CustomProgressBar data={item.response_summary} />
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.errorMessage}>
          <Typography variant='h6'>{errorMessage}</Typography>
        </Box>
      )}
      {openDialog && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={formatMeetingData(selectedMeeting?.date)}
          onClose={() => setOpendialog(false)}
          chatRIAMetadata={{}}
          source={CENTER_MAPPING[selectedMeeting?.center]}
          metadata={metadata}
          infoComponent={<MeetingInfoChipCard meetingInfo={selectedMeeting} />}
          disableChatRia={false}
          secondaryDrawerTitle=''
        />
      )}
    </Box>
  );
};

export default React.memo(Summary);
