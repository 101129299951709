import { Box, Divider, Tooltip, Typography } from '@mui/material';
import React, { useState, useContext } from 'react';
import { uniqueId } from 'lodash';
import styles from './styles/reportButton.styles';
import PlusIcon from '../../assets/svgs/Report/PlusIcon';
import ReportAction from './ReportAction';
import CaretDownIcon from '../../assets/svgs/Icons/CaretDown';
import useReportingHandler from './hooks/useReportingHandler';
import CreateNewReportForm from './components/CreateNewReportForm';
import Store from '../../store';
import Actions from '../../store/actions';
import ReportLoader from './components/ReportLoader';

const ReportButton = ({
  data,
  addToReport,
  prefixId = 'report_customization',
  sectionType = 'custom',
  style = {}
}: any) => {
  const { state, dispatch } = useContext<any>(Store);
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [anchorElButton, setAnchorElButton] = React.useState<null | HTMLElement>(null);
  const { fetchActiveReportDetails } = useReportingHandler();
  const [openCreateNewReportDialog, setOpenCreateNewReportDialog] = useState<boolean>(false);

  const handleReportClick = (e: any) => {
    setAnchorElButton(e.currentTarget);
    setOpenReport(true);
  };
  const handleAddToReport = (reportDetails: any) => {
    dispatch({
      type: Actions.REPORT_SAVING,
      value: true
    });
    addToReport(data, reportDetails);
    setOpenReport(false);
  };

  const handleAddToActiveReport = async () => {
    dispatch({
      type: Actions.REPORT_SAVING,
      value: true
    });
    const activeReportData = await fetchActiveReportDetails();
    if (Object.keys(activeReportData).length === 0) {
      setOpenCreateNewReportDialog(true);
      dispatch({
        type: Actions.REPORT_SAVING,
        value: false
      });
    } else {
      const random = Math.floor(Math.random() * 1000);
      let id = '';
      if (sectionType === 'snapshot') {
        id = prefixId;
      } else {
        id = uniqueId(`${prefixId}${random}_`);
      }
      handleAddToReport({ ...activeReportData, reportId: id });
      await dispatch({
        type: Actions.SET_CART_ITEMS,
        value: state.cartItems + 1
      });
      setOpenReport(false);
    }
  };
  const handleSubmit = (reportDetails: any) => {
    const random = Math.floor(Math.random() * 1000);
    const id = uniqueId(`${prefixId}_${random}_`);
    handleAddToReport({ ...reportDetails, reportId: id });
    setOpenCreateNewReportDialog(false);
  };
  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        sx={{ ...styles.buttonContainer, ...style?.buttonContainer }}>
        {state?.reportSaving && (
          <ReportLoader
            open={state?.reportSaving}
            message='Saving to your report. Please wait...'
          />
        )}
        <Tooltip title='Add content to your active report'>
          <Box
            display='flex'
            pl={1.5}
            gap={1}
            justifyContent='flex-start'
            alignItems='center'
            onClick={handleAddToActiveReport}
            sx={{ ...styles.reportButton, ...style?.reportButton }}>
            <Typography
              align='right'
              variant='subtitle2'
              sx={{ ...styles.reportText, ...style?.reportText }}>
              <PlusIcon sx={{ ...styles.plusIcon, ...style?.reportText }} /> Report
            </Typography>
          </Box>
        </Tooltip>
        <Divider orientation='vertical' sx={styles.divider} />
        <Tooltip title='Add content to a previous report or a newly created report'>
          <Box
            display='flex'
            p={1}
            alignItems='center'
            onClick={handleReportClick}
            sx={{ ...styles.reportButton, ...style?.reportButton }}>
            <CaretDownIcon sx={{ ...styles.iconButton, ...style?.reportText }} />
          </Box>
        </Tooltip>
      </Box>

      {openReport && (
        <ReportAction
          open={openReport}
          prefixId={prefixId}
          anchorEl={anchorElButton}
          handleAddToReportId={handleAddToReport}
          handleClose={() => {
            setOpenReport(false);
          }}
        />
      )}
      {openCreateNewReportDialog && (
        <CreateNewReportForm
          openCreateNewReportDialog={openCreateNewReportDialog}
          setOpenCreateNewReportDialog={setOpenCreateNewReportDialog}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default React.memo(ReportButton);
