import { GridColDef } from '@mui/x-data-grid-pro';
import { Filter } from '../../components/Filters';
import DatagridTooltip from '../../components/Datagrid/DatagridTooltip';
import DatagridColorNumber from './components/DatagridColorNumber/DatagridColorNumber';
import DocumentHistory from './components/DocumentsHistory';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import DatagridDate from '../../components/Datagrid/DatagridDate';
import sortProductComparator from './utils/sortProductComparator';
import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const SEARCH_IGNORE_FIELDS = ['doc_hash', 'field_associated_media_2', 'guidance_pk'];

export interface HTAFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const HTA_FILTERS = [
  {
    id: 1,
    label: 'Document Type',
    value: 'nice_doc_type',
    elasticSearchType: 'nice_doc_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Guidance Type',
    value: 'nice_guidance_type',
    elasticSearchType: 'nice_guidance_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Status',
    value: 'std_status',
    elasticSearchType: 'std_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Last Updated Date',
    value: 'last_updated_date',
    elasticSearchType: 'last_updated_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Published Date',
    value: 'published_date',
    elasticSearchType: 'published_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const HTA_PIE_CHART = [
  {
    label: 'Document Type',
    apiKey: 'nice_doc_type',
    graphItem: 'nice_doc_type',
    count: 3
  },
  {
    label: 'Advice Type',
    apiKey: 'nice_advice_type',
    graphItem: 'nice_advice_type',
    count: 3
  },
  {
    label: 'Status',
    apiKey: 'std_status',
    graphItem: 'std_status',
    count: 1
  }
];

export const HTA_COLUMNS: GridColDef[] = [
  {
    field: 'document_count',
    headerName: 'Documents',
    headerClassName: 'table-header',
    width: 140,
    cellClassName: 'table-row',
    sortable: false,
    renderCell: DocumentHistory
  },
  {
    field: 'guidance_ref',
    headerName: 'Reference Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridColorNumber
  },
  {
    field: 'title',
    headerName: 'Title',
    headerClassName: 'table-header',
    width: 450,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'alt_source_names',
    headerName: 'Alternate Source',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'last_updated_date',
    headerName: 'Last Updated Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'nice_doc_type',
    headerName: 'Nice Document Type',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    sortComparator: sortProductComparator,
    renderCell: DatagridTooltip
  },
  {
    field: 'published_date',
    headerName: 'Published Date',
    headerClassName: 'table-header',
    width: 150,
    cellClassName: 'table-row',
    renderCell: DatagridDate
  },
  {
    field: 'std_status',
    headerName: 'Status',
    headerClassName: 'table-header',
    width: 130,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  }
];

export const HTA_COLUMN_STYLES = {
  title: 'capitalize',
  alt_source_names: 'capitalize',
  std_status: 'capitalize'
};

export const MAX_FREE_DOWNLOADABLE_DATA = 50;

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'HTA',
  postfix: date,
  additional: '',
  extension: ''
};

export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3'
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  enableLabels: 'both'
};

export const HTA_REPORT_QUERIES = [
  {
    id: 'hta_documents_by_year',
    title: {
      title: 'HTA Documents by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'nice_doc_type',
    title: {
      title: 'Document Type',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['nice_doc_type'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'nice_advice_type',
    title: {
      title: 'Advice Type',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['nice_advice_type'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'std_status',
    title: {
      title: 'Status',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['std_status'],
      agg_function: 'count'
    },
    graphStyle: pieChartStyles
  }
];

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const HTA_GUIDANCE_COLUMN_NAME_MAPPING = {
  guidance_ref: 'Reference Number',
  title: 'Title',
  alt_source_names: 'Alternate Source',
  last_updated_date: 'Last Updated Date',
  nice_doc_type: 'Nice Document Type',
  published_date: 'Published Date',
  std_status: 'Status'
};
