import { Box } from '@mui/material';
import React from 'react';

const ReferenceText = React.forwardRef<HTMLDivElement, any>(({ referenceText }, ref) => {
  const getSubString = (text: string) => {
    if (text.length > 30) {
      return `${text.substring(0, 30).trim()}...`;
    }
    return text;
  };

  return (
    <Box
      ref={ref}
      position='absolute'
      bottom='-15px'
      textAlign='center'
      zIndex={100}
      m={1}
      sx={{ display: 'flex', justifyContent: 'center' }}>
      {referenceText.text &&
        // eslint-disable-next-line array-callback-return, consistent-return
        referenceText?.text?.split(' / ').map((item: string) => {
          if (item.trim() !== '') {
            return (
              <Box
                key={item}
                sx={{
                  fontFamily: 'Arial, sans-serif',
                  fontStyle: 'italic',
                  mt: '1rem',
                  ml: '1rem',
                  mb: '10px',
                  display: 'flex',
                  fontSize: '12px',
                  border: '1px solid #ddd',
                  padding: '0 0.5rem',
                  alignItems: 'center'
                }}>
                {getSubString(item)}
              </Box>
            );
          }
        })}
      <Box
        sx={{
          fontFamily: 'Arial, sans-serif',
          fontStyle: 'italic',
          mt: '1rem',
          ml: '1rem',
          mb: '10px',
          display: 'flex',
          fontSize: '12px',
          border: '1px solid #ddd',
          padding: '0 0.5rem',
          alignItems: 'center'
        }}>
        Source:&nbsp;
        <a
          href={referenceText.href}
          target='_blank'
          style={{ fontSize: '12px', textAlign: 'center' }}
          rel='noreferrer'>
          RIA
        </a>
        {referenceText?.originalUrl ? (
          <>
            &nbsp;/&nbsp;
            <a
              href={referenceText.originalUrl}
              target='_blank'
              style={{ fontSize: '12px', textAlign: 'center' }}
              rel='noreferrer'>
              Original
            </a>
          </>
        ) : null}
      </Box>
    </Box>
  );
});
export default React.memo(ReferenceText);
