import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { useLocation } from 'react-router';
import { apiDataToCardProps } from '../../../SearchResults/constants';
import Store from '../../../../store';
import ResultsStore from '../../../../store/SearchResults';
import AuthContext from '../../../../store/Auth/AuthProvider';
import styles from '../../styles/ApplicationsTab.styles';
import Actions from '../../../../store/actions';
import Card from '../../../SearchResults/components/Card';
import AskRiaButton from '../../../../components/Buttons/AskRiaButton';
import SourceDropDown from '../../../Home/components/SourceDropDown';
import { SOURCE_DROPDOWN_TYPES } from '../../../Home/const';
import { Module, SourceDropdown } from '../../../Home/types';
import { createSourceModulePayloadMapping, getFilteredSourceDropdown } from '../../../Home/utils';
import AriaDrawer from '../../../SearchResults/components/AriaDrawer';
import { BOTTOM_FILTERS_ORDER } from '../../../../components/NewFilters/constants';
import RESULT_VIEW_TYPES from '../../../SearchResults/components/constants';
import getAriaFilters_ from '../../../SearchResults/utils/getAriaFilters';
import {
  sortFilterCategoriesOptions,
  sortFilterCatgories
} from '../../../../components/NewFilters/utils';
import { formatDocumentResults } from '../../../SearchResults/utils/documentResultsUtils';
import { getGenericSearch } from '../../../../api/pages/ResultsPage';
import getUserDetails from '../../../SearchResults/utils/getUserDetails';
import ResultActions from '../../../../store/SearchResults/actions';
import NoResultsView from '../NoResultsView';
import { ApplicationsProps } from '../../interface';
import { ENBALE_APPLICATION_BASED_ON_SOURCE } from '../../constants';

const Applications = ({
  applications,
  handleFavoriteHook,
  handleProjectHook,
  handleSubscriptionHook,
  gridSizes = { xs: 12, md: 6, lg: 4, xl: 4 }
}: ApplicationsProps) => {
  const { currentUser } = useContext(AuthContext);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch } = useContext(Store) as any;
  const location = useLocation();
  const [askRiaFilters, setAskRiaFilters] = useState([]);
  const [applicationData, setApplicationData] = useState<any>({});
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);

  const applicationSourceDropdownList = useMemo(() => {
    const applicationSources = new Set(Object.keys(applicationData));
    const sources = getFilteredSourceDropdown(
      (module: Module) =>
        ((ENBALE_APPLICATION_BASED_ON_SOURCE.includes(module?.value) ||
          module?.isApplicationsView) ??
          false) &&
        applicationSources.has(module.subscriptionKey ?? module.value)
    );
    return sources;
  }, [applicationData]);

  const handleSourceChange = (values: SourceDropdown[]) => {
    setSelectedSources(values);
  };

  const handleButtonClick = () => {
    setAriaModalOpen(true);
    dispatch({ type: Actions.SET_SHOW_NEW_FILTERS, value: false });
  };

  const filteredApplications: any = useMemo(() => {
    // Create a set of selected source database names
    const selectedSourcesSet = new Set(
      selectedSources.flatMap(source =>
        source.module.map(module => module.subscriptionKey ?? module.value)
      )
    );

    // Filter the applicationData based on the selected sources set
    const result = Object.entries(applicationData)
      .filter(([key]) => selectedSourcesSet.has(key)) // Destructure correctly
      .reduce((obj: any, [key, value]) => {
        // Assign value to the result object
        // eslint-disable-next-line no-param-reassign
        obj[key] = value;
        return obj;
      }, {});

    return Object.values(result).flat();
  }, [selectedSources, applicationData]);

  const getAriaFilters = async (selectedFilters = {}, shouldCallAPI = true) => {
    // if single source then sort the filters based on default category order that need to be displayed in bottom
    let defaultBottomFilters: [] = [];
    const sources = createSourceModulePayloadMapping(selectedSources);
    if (Object.keys(sources)?.length > 0) {
      const isMultipleSourceSearch =
        Object.keys(sources as Record<string, any>)?.length > 1 ||
        Object.values(sources as Record<string, any>)[0]?.length > 1;
      if (!isMultipleSourceSearch) {
        defaultBottomFilters =
          BOTTOM_FILTERS_ORDER[
            `${RESULT_VIEW_TYPES.DOCUMENT}-${Object.keys(sources)[0]}-${
              sources[Object.keys(sources)[0]][0]
            }`
          ];
      }
    }
    if (!shouldCallAPI) {
      let ariaFilters = getAriaFilters_(resultsState, sortFilterCategoriesOptions);
      ariaFilters = ariaFilters?.sort((a: any, b: any) =>
        sortFilterCatgories(a.label, b.label, defaultBottomFilters)
      );
      setAskRiaFilters(ariaFilters);
    } else {
      resultsDispatch({
        type: ResultActions.SET_TOP_10_SUMMARY,
        value: []
      });
      resultsDispatch({ type: ResultActions.SET_RIA_RESPONSE_TITLE, value: 'Top Result' });
      resultsDispatch({ type: ResultActions.SET_SHOW_TOP_10_SUMMARY, value: false });
      const filtersForAria = {
        ...(resultsState?.ariaEntityIds &&
          resultsState?.ariaEntityIds?.length > 0 && { entity_id: resultsState.ariaEntityIds }), // add entity id list in payload only if it is present
        ...selectedFilters
      };
      resultsDispatch({ type: ResultActions.SET_FILTERS, value: { ...filtersForAria } });
      const userDetails = getUserDetails(currentUser);
      const API_PAYLOAD: any = {
        source: createSourceModulePayloadMapping(selectedSources),
        query: resultsState.decryptedPayload.query,
        view_type: RESULT_VIEW_TYPES.DOCUMENT,
        filterSource: 'default',
        filters: filtersForAria,
        userDetails
      };
      if (location.pathname.match('saved')) {
        API_PAYLOAD.search_from_favorites = true;
      } else if (location.pathname.match('project')) {
        const projectId = location.pathname.split('/')?.[3] ?? '';
        API_PAYLOAD.search_project_id = projectId;
      }
      resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });
      const res = await getGenericSearch(API_PAYLOAD);
      resultsDispatch({
        type: ResultActions.SET_DOCUMENT_RESULTS,
        value: formatDocumentResults(res?.data?.body?.result)
      });
      resultsDispatch({ type: ResultActions.SET_FILTERS, value: { ...filtersForAria } });
      resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: false });
    }
  };

  const getDocumentCount = useCallback((applData: any, type: string) => {
    let pageCount = 0;
    applData.flat().forEach((appl: any) => {
      if (type === 'documentCount') {
        pageCount += appl.document_total_page_count;
      } else {
        pageCount += appl.document_count;
      }
    });

    return pageCount;
  }, []);

  const triggerChatRIA = (source: string, identifier: string) => {
    let newAppl: any[] = [];

    const apps: Array<any> =
      Object.keys(applicationData).length !== 0
        ? Object.entries(applicationData).reduce(
            (appl: any[], [, value]: [string, any]) => [...appl, ...value],
            []
          )
        : [];
    const selectedApplicationIndex = apps?.findIndex(
      (x: { source: string; identifier: string }) =>
        x.source === source && x.identifier === identifier
    );
    const updatedApplicationList = apps.filter((app: any) => app.identifier !== identifier);
    newAppl = [
      apps[selectedApplicationIndex],
      ...updatedApplicationList.filter(
        app => app.source_index !== 'ct' && app.source_index !== 'euctr'
      )
    ];
    dispatch({ type: Actions.SET_CHATRIA_TRIGGERED_FROM, value: 'application' });
    dispatch({ type: Actions.SET_APPLICATION_SOURCE, value: source });
    dispatch({ type: Actions.SET_APPLICATION_LIST, value: newAppl });
    dispatch({ type: Actions.SET_CHATRIA_OPEN, value: true });
  };

  useEffect(() => {
    const finalData = {} as any;
    // getting unique source from applications List
    applications?.forEach((row: any) => {
      if (apiDataToCardProps[row.source]) {
        const sourceApps = apiDataToCardProps[row.source]?.(row);
        if (Object.hasOwnProperty.call(finalData, row.source)) {
          finalData[row.source].push(sourceApps);
        } else {
          finalData[row.source] = [sourceApps];
        }
      }
    });
    setApplicationData(finalData);
  }, [applications]);

  useEffect(() => {
    setSelectedSources(cloneDeep(applicationSourceDropdownList));
  }, [applicationSourceDropdownList, applicationData]);

  useEffect(() => {
    getAriaFilters({}, false);
  }, [resultsState.documentResults]);

  if (applications.length === 0) return <NoResultsView text=' No Applications are saved' />;

  return (
    <Box sx={styles.root}>
      <Stack sx={styles.topStack}>
        <SourceDropDown
          selectedSources={selectedSources}
          handleChange={handleSourceChange}
          options={applicationSourceDropdownList}
          anchorEl={sourceDropDownAnchorEl}
          setAnchorEl={setSourceDropDownAnchorEl}
          type={SOURCE_DROPDOWN_TYPES.APPLICATIONS_VIEW}
          miniminumOneSelected
          showApplyButton
          makeSearch={() => {}}
        />
        <Box onClick={() => handleButtonClick()}>
          <AskRiaButton iconId='aria' setOpenAriaSearch={setAriaModalOpen} text='Ask RIA' />
        </Box>
      </Stack>
      <Divider variant='middle' sx={styles.divider} />
      <Box overflow='auto'>
        <Grid container>
          {filteredApplications.flat().map((item: any) => (
            <Grid
              item
              key={item.identifier}
              xs={gridSizes.xs}
              md={gridSizes.md}
              lg={gridSizes.lg}
              xl={gridSizes.xl}
              sx={styles.applicationsGrid}>
              <Card
                data={item}
                triggerChatRIA={triggerChatRIA}
                setFavoriteInHook={handleFavoriteHook}
                setInProjectInHook={handleProjectHook}
                setSubscriptionInHook={handleSubscriptionHook}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {ariaModalOpen && (
        <AriaDrawer
          applications={filteredApplications?.flat() ?? []}
          sources={createSourceModulePayloadMapping(selectedSources)}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          filters={askRiaFilters}
          getFilters={getAriaFilters}
          globalDispatch={dispatch}
          isCount
          DocCount={getDocumentCount(filteredApplications, 'documentCount')}
          PageCount={getDocumentCount(filteredApplications, 'pageCount')}
          triggeredFromUserProfile
        />
      )}
    </Box>
  );
};

export default Applications;
