import React from 'react';
import { Box, LinearProgress, Typography, Dialog, Stack } from '@mui/material';
import styles from '../styles/nameConfirmationDialog.styles';
import LogoIcon from '../../../assets/svgs/Icons/LogoIcon';

const ReportLoader = ({ open, message }: { open: boolean; message: string }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
      }}
      sx={{
        ...styles.clearDialog,
        '& >.MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,0.25)'
        }
      }}>
      <Stack direction='column' spacing='24px' sx={styles.container}>
        <Stack alignItems='center' direction='column' justifyContent='center' spacing={3}>
          <LogoIcon sx={{ fontSize: 60 }} />
          <Box width={240} mt={2} mb={3}>
            <LinearProgress sx={styles.linerProgress} />
          </Box>
          <Typography color='gray.900' fontSize={14} fontWeight={500} lineHeight='24px'>
            {message}
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default React.memo(ReportLoader);
