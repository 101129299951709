import React from 'react';
import { Typography, Box, Chip } from '@mui/material';

interface MeetingInfo {
  month_year: string;
  applicant_initials: string;
  center: string;
  name: string;
  details: string;
}

interface MeetingInfoChipCardProps {
  meetingInfo: MeetingInfo;
}

const MeetingInfoChipCard: React.FC<MeetingInfoChipCardProps> = ({ meetingInfo }) => (
  <Box width={301} borderRadius={2} display='flex' flexDirection='column'>
    <Box display='flex' justifyContent='space-between' alignItems='center' mt={1}>
      <Typography
        fontSize={16}
        color='gray.600'
        px={1}
        border='1px solid'
        borderColor='gray.light'
        borderRadius={2}>
        {`${meetingInfo?.month_year} ${meetingInfo?.applicant_initials}`}
      </Typography>
      <Chip
        label={meetingInfo?.center}
        size='small'
        sx={{ color: 'red.200', fontSize: 10, fontWeight: 800 }}
      />
    </Box>
    <Box ml={1}>
      {/* Title */}
      <Typography fontSize={16} fontWeight={700}>
        {meetingInfo?.name}
      </Typography>

      {/* Description */}
      <Typography fontSize={14} fontWeight={500}>
        {meetingInfo?.details}
      </Typography>
    </Box>
  </Box>
);

export default React.memo(MeetingInfoChipCard);
