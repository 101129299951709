import { MouseEvent, useState, useContext, ReactNode } from 'react';

import {
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@mui/material';
import { updateSubscriptionPreferences } from '../../api/modules/userNotification';
import { UnsubscribeIcon, SubscribeIcon } from '../../assets/svgs/Icons';
import { CHANNELS } from '../../pages/Notifications/constants';
import CustomCheckbox from '../CustomComponents/Checkbox';

// Store
import Actions from '../../store/actions';
import Store from '../../store';
import { DocumentSearchSubscription } from '../../pages/Notifications/components/NotificationInterface';

import styles from './styles/SubscriptionPreferences.styles';

export interface SubscriptionPreferencesType {
  preferences: {
    [key: string]: {
      label: string;
      value: boolean;
    };
  };

  [key: string]: any;
}

interface SubscriptionPreferencesProps {
  isSubscribed: boolean;
  subscriptionLoading: boolean;
  manageListLoading: boolean;
  subscriptionPreferences: SubscriptionPreferencesType;
  setSubscriptionPreferences: Function;
  source: string;
  id: string;
  invokedBy: (typeof CHANNELS)[number];
  setShowUnsubscribeAlert: Function;
  handleSubscribe: Function;
  documentSearchSubscriptionTerms?: DocumentSearchSubscription[];
  preMenuItems?: ReactNode;
  postMenuItems?: ReactNode;
}

const SubscriptionPreferences = ({
  isSubscribed,
  subscriptionLoading,
  manageListLoading,
  subscriptionPreferences,
  setSubscriptionPreferences,
  source,
  id,
  invokedBy,
  setShowUnsubscribeAlert,
  handleSubscribe,
  documentSearchSubscriptionTerms = [],
  preMenuItems,
  postMenuItems
}: SubscriptionPreferencesProps) => {
  const { dispatch } = useContext<any>(Store);
  const [loadingPreferenceId, setLoadingPreferenceId] = useState<string | null>(null);

  const updateSubscriptionPreferencesMenu = async (prefId: string, value: boolean) => {
    setLoadingPreferenceId(prefId);
    try {
      const res = await updateSubscriptionPreferences({
        source,
        entityId: id,
        preferenceId: prefId,
        preferenceValue: value,
        entityType: invokedBy
      });
      if (res && res.status === 200 && !res?.data?.body?.is_error) {
        const prefs = { ...subscriptionPreferences };
        prefs.preferences[prefId].value = value;
        setSubscriptionPreferences(prefs);
      } else {
        throw new Error(`Failed to update subscription preference for ${prefId}`);
      }
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: 'Something went wrong', status: true }
      });
    } finally {
      setLoadingPreferenceId(null);
    }
  };

  const handleSubscribeUnsubscribe = async (e: MouseEvent<HTMLElement>) => {
    if (isSubscribed) {
      e?.stopPropagation();
      setShowUnsubscribeAlert(true);
    } else {
      await handleSubscribe?.();
    }
  };

  const shouldShowCheckboxes = invokedBy !== 'data_source' && invokedBy !== 'document_search';

  const hasDocumentSearchTerms =
    invokedBy === 'document_search' && documentSearchSubscriptionTerms?.length > 0;

  return (
    <>
      {preMenuItems && (
        <>
          {preMenuItems}
          <Divider sx={styles.divider} />
        </>
      )}
      {isSubscribed &&
        (shouldShowCheckboxes ||
          (hasDocumentSearchTerms && (
            <ListItem>
              <ListItemText sx={styles.listItemText}>
                {invokedBy === 'document_search' ? 'Subscribed Terms' : 'Manage Notifications'}
              </ListItemText>
            </ListItem>
          )))}
      {manageListLoading && invokedBy !== 'data_source' && isSubscribed && (
        <MenuItem>
          <ListItemIcon>
            <CircularProgress size={20} />
          </ListItemIcon>
          <ListItemText sx={{ color: 'gray.700' }}>Loading...</ListItemText>
        </MenuItem>
      )}
      {!manageListLoading &&
        shouldShowCheckboxes &&
        isSubscribed &&
        Object.entries(subscriptionPreferences?.preferences).map(([key, preferenceData]: any) => (
          <MenuItem
            key={key}
            disabled={loadingPreferenceId === key}
            onClick={e => {
              e.stopPropagation();
              updateSubscriptionPreferencesMenu(key, !preferenceData?.value);
            }}>
            <ListItemIcon>
              {loadingPreferenceId === key ? (
                <CircularProgress size={16} sx={{ marginLeft: '10px' }} />
              ) : (
                <CustomCheckbox
                  size='small'
                  value={key}
                  sx={{ padding: 0, marginLeft: '10px' }}
                  checked={preferenceData.value}
                />
              )}
            </ListItemIcon>
            <ListItemText sx={{ color: 'gray.700' }}>{preferenceData?.label ?? ''}</ListItemText>
          </MenuItem>
        ))}
      {!manageListLoading &&
        hasDocumentSearchTerms &&
        isSubscribed &&
        documentSearchSubscriptionTerms?.map(documentSearchSubscriptionTerm => (
          <MenuItem
            key={`document_search_${documentSearchSubscriptionTerm.document_search_subscription_id}`}>
            <ListItemText sx={styles.listItemTextGrey}>
              {documentSearchSubscriptionTerm?.subscription_search_data?.term ?? ''}
            </ListItemText>
          </MenuItem>
        ))}
      {isSubscribed && (shouldShowCheckboxes || hasDocumentSearchTerms) && (
        <Divider sx={styles.divider} />
      )}
      <MenuItem disabled={subscriptionLoading} onClick={handleSubscribeUnsubscribe}>
        <ListItemIcon>
          {subscriptionLoading && <CircularProgress size={16} sx={{ marginLeft: '10px' }} />}
          {!subscriptionLoading &&
            (isSubscribed ? (
              <UnsubscribeIcon sx={styles.subscribeIcon} />
            ) : (
              <SubscribeIcon sx={styles.subscribeIcon} />
            ))}
        </ListItemIcon>
        <ListItemText sx={styles.listItemTextGrey}>
          {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
        </ListItemText>
      </MenuItem>
      {postMenuItems && <Divider sx={styles.divider} />}
      {postMenuItems !== null && postMenuItems}
    </>
  );
};

SubscriptionPreferences.defaultProps = {
  preMenuItems: null,
  postMenuItems: null,
  documentSearchSubscriptionTerms: []
};

export default SubscriptionPreferences;
