import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, ListSubheader, Stack } from '@mui/material';
import { uniqueId } from 'lodash';
import { SOURCE_MENU_ITEMS } from '../../Home/const';
import { sourceFlagMapping } from '../../SearchResults/constants';
import notificationsStyles from '../styles/Notifications.styles';
import {
  addSubscriptionSourcePreference,
  updateSubscriptionSourcePreference
} from '../../../api/modules/userNotification';
import { Module } from '../../Home/types';
import SwitchWithLoader from '../../../components/Switch/SwitchWithLoader';

// store
import NotificationsStore from '../../../store/Notifications';
import NotificationsActions from '../../../store/Notifications/actions';
import { getSourceSubscriptions } from '../utils';

const SourceSubscriptionListItem = ({
  subItem,
  subscribed,
  updateSourceSubscriptionState,
  parentLoading
}: {
  subItem: Module;
  subscribed: boolean;
  updateSourceSubscriptionState: Function;
  parentLoading: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const isChecked = event.target.checked;
      setError('');
      setLoading(true);

      const res = await updateSubscriptionSourcePreference([
        {
          source: subItem.subscriptionKey,
          subscribed: isChecked
        }
      ]);
      if (res?.status === 200)
        updateSourceSubscriptionState(subItem.subscriptionKey as string, isChecked);
      else if (res?.status === 404) {
        const result = await addSubscriptionSourcePreference();
        if (result.status !== 200) throw new Error('Could not update the subscription');

        const updatedRes = await updateSubscriptionSourcePreference([
          {
            source: subItem.subscriptionKey,
            subscribed: isChecked
          }
        ]);
        if (updatedRes.status !== 200) throw new Error('Could not update the subscription');
        updateSourceSubscriptionState(subItem.subscriptionKey as string, isChecked);
      } else throw new Error('Could not update the subscription');
    } catch (e) {
      setError('Could not update the subscription');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ListItem
      dense
      key={subItem?.subscriptionKey}
      secondaryAction={
        <SwitchWithLoader
          checked={subscribed}
          onChange={handleChange}
          loading={loading || parentLoading}
          size='small'
        />
      }>
      <ListItemText primary={subItem.label} sx={notificationsStyles.subHeader} secondary={error} />
    </ListItem>
  );
};
const SourceSubscription = () => {
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);
  const [loading, setLoading] = useState(true);

  const loadSourceSubscriptions = async () => {
    setLoading(true);
    try {
      const subscriptions = await getSourceSubscriptions();
      notificationsDispatch({
        type: NotificationsActions.SET_SUBSCRIBED_SOURCES,
        value: subscriptions
      });
    } finally {
      setLoading(false);
    }
  };

  const updateSourceSubscriptionState = async (source: string, subscribed: boolean) => {
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_SOURCES,
      value: { ...notificationsState.subscribedSources, [source]: subscribed }
    });
  };

  useEffect(() => {
    loadSourceSubscriptions();
  }, []);

  return (
    <Box sx={{ height: '90%', overflow: 'auto' }}>
      {SOURCE_MENU_ITEMS.map(item => {
        const sources = Array.from(
          new Set(
            item.module
              .filter(moduleItem => moduleItem.canSubscribeSource)
              .map(moduleItem => moduleItem.sourceValue)
          )
        );
        const hasSubscribedSource = item.module.some(moduleItem => moduleItem.canSubscribeSource);
        if (!hasSubscribedSource) {
          return null;
        }
        return (
          <List
            sx={notificationsStyles.sourceSubscriptionList}
            key={sources.join('')}
            component='nav'
            aria-labelledby='nested-list-subheader'
            subheader={
              <ListSubheader
                sx={notificationsStyles.sourceSubscriptionListHeader}
                component='div'
                id='nested-list-subheader'>
                {sources && (
                  <Stack direction='row' spacing={1}>
                    {sources.map(source => {
                      const FlagComponent = sourceFlagMapping[source];
                      return (
                        <Box key={uniqueId()} sx={notificationsStyles.flagIconBox}>
                          <FlagComponent
                            key={uniqueId()}
                            sx={{
                              ...(source === 'jp' && {
                                scale: '1.1',
                                // path with id #jp will be scaled by 1.1
                                'path#jp-circle': {
                                  scale: '0.5',
                                  transformOrigin: 'center'
                                }
                              }),
                              ...notificationsStyles.flagIcon
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Stack>
                )}
                {item.label}
              </ListSubheader>
            }>
            {item.module
              .filter(moduleItem => moduleItem.canSubscribeSource && moduleItem.subscriptionKey)
              .map(subItem => {
                return (
                  <SourceSubscriptionListItem
                    key={subItem?.subscriptionKey}
                    subItem={subItem}
                    subscribed={
                      notificationsState.subscribedSources?.[subItem.subscriptionKey as string] ??
                      false
                    }
                    updateSourceSubscriptionState={updateSourceSubscriptionState}
                    parentLoading={loading}
                  />
                );
              })}
          </List>
        );
      })}
    </Box>
  );
};
export default SourceSubscription;
