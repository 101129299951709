import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { InfoOutlined, KeyboardArrowDown, RemoveCircleOutline } from '@mui/icons-material';

import { CloseIcon, AddCircleVariantIcon } from '../../assets/svgs/Icons';

import styles from './styles/DocumentSearchKeywordSelection.styles';
import { getDocumentSearchKeywords } from '../../api/modules/userNotification';

// store
import Store from '../../store';
import Actions from '../../store/actions';

const DocumentSearchKeywordSelection = ({
  openKeywordPopup,
  setOpenKeywordPopup,
  searchQuery,
  handleSubscribe
}: {
  openKeywordPopup: boolean;
  setOpenKeywordPopup: Dispatch<React.SetStateAction<boolean>>;
  searchQuery: string;
  // eslint-disable-next-line no-unused-vars
  handleSubscribe: (documentSubscribeQuery: string) => Promise<void>;
}) => {
  const { dispatch } = useContext(Store) as any;

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [previousKeywords, setPreviousKeywords] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentMergeMenuIndex, setCurrentMergeMenuIndex] = useState(-1);

  const isMergeMenuOpen = Boolean(anchorEl);

  const finalKeywordsQuery = useMemo(
    () => keywords.map(keyword => `"${keyword}"`).join(' AND '),
    [keywords]
  );

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpenKeywordPopup(false);
  };

  const submitKeywordsWrapper = async () => {
    setSubscribeLoading(true);
    await handleSubscribe(finalKeywordsQuery);
    setSubscribeLoading(false);
    setOpenKeywordPopup(false);
  };

  const getKeywords = async () => {
    setLoading(true);
    try {
      const response = await getDocumentSearchKeywords(searchQuery);
      const keywordsList = response?.data?.body?.keywords ?? [];
      if (keywordsList.length > 0) setKeywords(keywordsList);
      else {
        setKeywords([]);
        dispatch({
          type: Actions.SET_ALERT,
          value: {
            status: true,
            message: response?.data?.error?.message ?? 'Could not get keywords for the search query'
          }
        });
        setOpenKeywordPopup(false);
      }
    } catch (error) {
      setKeywords([]);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Could not get keywords for the search query'
        }
      });
      setOpenKeywordPopup(false);
    }
    setLoading(false);
  };

  const finalKeywordsQuerySentence = useMemo(() => {
    if (keywords.length === 0) return ''; // No keywords
    if (keywords.length === 1) return `${keywords[0]}`; // Single keyword
    if (keywords.length === 2) return `${keywords[0]} and ${keywords[1]}`; // Two keywords

    // For three or more keywords
    const lastKeyword = keywords[keywords.length - 1];
    const otherKeywords = keywords.slice(0, -1).join(', ');
    return `${otherKeywords}, and ${lastKeyword}`;
  }, [keywords]);

  const toggleEditing = () => {
    if (!isEditing) setPreviousKeywords([...keywords]);
    else setKeywords([...previousKeywords]);
    setIsEditing(!isEditing);
  };

  const handleMergeMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setCurrentMergeMenuIndex(index);
  };
  const handleMergeMenuClose = () => {
    setAnchorEl(null);
    setCurrentMergeMenuIndex(-1);
  };
  const mergeKeywords = () => {
    if (currentMergeMenuIndex === -1) return;
    const newKeywords = [...keywords];
    newKeywords[currentMergeMenuIndex] = `${keywords[currentMergeMenuIndex]} ${
      keywords[currentMergeMenuIndex + 1]
    }`;
    newKeywords.splice(currentMergeMenuIndex + 1, 1);
    setKeywords(newKeywords);
    setAnchorEl(null);
    setCurrentMergeMenuIndex(-1);
    handleMergeMenuClose();
  };

  const removeKeyword = (index: number) => {
    if (keywords.length <= 1 || index >= keywords.length) return;
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
  };

  const addNewRow = () => {
    setKeywords([...keywords, '']);
  };

  const isAnyKeywordEmpty = useMemo(
    () => keywords.some(keyword => keyword.trim() === ''),
    [keywords]
  );

  const handleKeywordChange = (index: number, value: string) => {
    setKeywords(prevKeywords => prevKeywords.map((keyword, i) => (i === index ? value : keyword)));
  };

  useEffect(() => {
    if (openKeywordPopup) getKeywords();
  }, [searchQuery, openKeywordPopup]);

  return (
    <Stack>
      <Dialog
        hideBackdrop
        disableEscapeKeyDown
        maxWidth='md'
        open={openKeywordPopup}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          elevation: 2,
          sx: styles.dialogPaper
        }}
        sx={styles.dialogWrapper}>
        <DialogContent sx={styles.dialogContent}>
          <Stack sx={styles.contentWrapper}>
            <Box sx={styles.titleBox}>
              <Typography sx={styles.titleText}>
                {loading ? 'Searching for Keywords' : 'Subscribe for Updates'}
              </Typography>
              <Box sx={styles.closeButtonBox}>
                <Tooltip title='Close'>
                  <IconButton onClick={() => setOpenKeywordPopup(false)} size='medium'>
                    <CloseIcon sx={styles.closeButtonIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={{ px: '16px' }}>
              <Typography sx={styles.subtitleText}>
                You will receive notifications for new results that exactly match
              </Typography>
              <Stack direction='row' spacing='4px' alignItems='center' justifyContent='center'>
                <Typography sx={styles.subtitleText}>
                  all the key terms listed below with the applied filters:
                </Typography>
                {!loading && (
                  <Tooltip
                    title={
                      <Box>
                        <Box>
                          You will only receive notifications for documents that include all the
                          specified terms:
                          <br />
                          <span style={styles.textSpan1}>
                            <strong>{finalKeywordsQuerySentence}</strong>
                          </span>
                        </Box>
                        <Box sx={styles.textSpan2}>
                          Please note: Synonyms for these terms are not included
                        </Box>
                      </Box>
                    }>
                    <InfoOutlined sx={styles.infoIcon} />
                  </Tooltip>
                )}
              </Stack>
              {loading && (
                <Box sx={styles.skeletonBox}>
                  <Skeleton variant='rounded' width='300px' height='48px' animation='wave' />
                </Box>
              )}
              {!loading && (
                <Box sx={styles.finalKeywordsQueryBox}>
                  <Typography sx={styles.finalKeywordsQuery}>{finalKeywordsQuery}</Typography>
                </Box>
              )}
              {!loading && isEditing && (
                <Stack alignItems='center' justifyContent='start' sx={styles.keywordStackWrapper}>
                  {keywords.map((keyword, rowIndex) => (
                    <Stack
                      // eslint-disable-next-line react/no-array-index-key
                      key={`keyword-${rowIndex}`}
                      direction='column'
                      alignItems='center'
                      justifyContent='center'>
                      <Box sx={styles.editBox}>
                        <Box flex={1} flexGrow={1}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            value={keyword}
                            onChange={e => handleKeywordChange(rowIndex, e.target.value)}
                            sx={{
                              // Root class for the input field
                              '& .MuiOutlinedInput-root': {
                                // Class for the border around the input field
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor:
                                    currentMergeMenuIndex !== -1 &&
                                    (currentMergeMenuIndex === rowIndex ||
                                      currentMergeMenuIndex + 1 === rowIndex)
                                      ? 'primary.600'
                                      : 'gray.300',
                                  borderRadius: '8px'
                                }
                              },
                              '& .MuiInputBase-input': { height: '16px', padding: '12px' }
                            }}
                          />
                        </Box>
                        <Tooltip title='Remove item'>
                          <IconButton
                            disabled={keywords.length <= 1}
                            color='error'
                            size='small'
                            onClick={() => removeKeyword(rowIndex)}>
                            <RemoveCircleOutline sx={styles.removeIcon} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Stack
                        direction='row'
                        justifyContent='center'
                        display={rowIndex === keywords.length - 1 ? 'none' : 'flex'}>
                        <Button
                          sx={styles.andButton}
                          aria-controls={isMergeMenuOpen ? 'merge-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={isMergeMenuOpen ? 'true' : undefined}
                          variant='contained'
                          disableElevation
                          onClick={event => {
                            handleMergeMenuOpen(event, rowIndex);
                          }}
                          endIcon={<KeyboardArrowDown />}>
                          AND
                        </Button>
                      </Stack>
                    </Stack>
                  ))}
                  <Tooltip title='Add new row'>
                    <IconButton color='primary' sx={styles.addNewRowButton} onClick={addNewRow}>
                      <AddCircleVariantIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              <Menu
                sx={styles.mergeMenu}
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={isMergeMenuOpen}
                onClose={handleMergeMenuClose}>
                <MenuItem selected onClick={handleMergeMenuClose} disableRipple>
                  AND
                </MenuItem>
                <MenuItem onClick={mergeKeywords} disableRipple>
                  Merge keywords
                </MenuItem>
              </Menu>
              <Stack
                direction='row'
                spacing='16px'
                alignItems='center'
                justifyContent='center'
                sx={{ mt: '24px' }}>
                <Button
                  variant='outlined'
                  onClick={toggleEditing}
                  disabled={keywords.length === 0 || loading || subscribeLoading}
                  sx={styles.editButton}>
                  {isEditing ? 'Cancel' : 'Edit'}
                </Button>
                <Button
                  variant='contained'
                  onClick={submitKeywordsWrapper}
                  disabled={
                    keywords.length === 0 || loading || subscribeLoading || isAnyKeywordEmpty
                  }
                  sx={styles.subscribeButton}>
                  {subscribeLoading && <CircularProgress size={20} />}
                  {!subscribeLoading && isEditing ? 'Done & Subscribe' : 'Subscribe'}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default DocumentSearchKeywordSelection;
