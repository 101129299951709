import React, { useMemo } from 'react';
import { isObject } from 'lodash';
import { Box, Stack, Popover, Typography } from '@mui/material';
import { DOCUMENT_SEARCH_SUBTEXT, sourceFlagMapping } from '../../SearchResults/constants';
import { getParentSourceLabel } from '../../Home/utils';
import { InfoIconSummary } from '../../../assets/svgs/Icons';
import styles from '../styles/NotificationFlag.styles';

const NotificationFlag = ({
  source,
  id = 'icon',
  showDetails
}: {
  source: Record<string, string[]> | string;
  id?: string;
  showDetails?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const flags = useMemo(() => {
    if (isObject(source)) {
      const uniqueFlags = new Set<React.ElementType>();
      const finalFlags: React.JSX.Element[] = [];

      Object.keys(source).forEach((name: string) => {
        const IndividualFlag = sourceFlagMapping[name] as React.ElementType;
        if (IndividualFlag && !uniqueFlags.has(IndividualFlag)) {
          uniqueFlags.add(IndividualFlag);
          finalFlags.push(
            <IndividualFlag
              key={name}
              sx={{
                ...(name === 'jp' && {
                  scale: '1.1',
                  // path with id #jp will be scaled by 1.1
                  'path#jp-circle': {
                    scale: '0.5',
                    transformOrigin: 'center'
                  }
                }),
                ...styles.flagIcon
              }}
            />
          );
        }
      });
      return finalFlags;
    }

    const IndividualFlag = sourceFlagMapping[source as string] as React.ElementType;
    if (!IndividualFlag) return [];

    return [
      <IndividualFlag
        key={source}
        id={id}
        sx={{
          ...(source === 'jp' && {
            scale: '1.1',
            // path with id #jp will be scaled by 1.1
            'path#jp-circle': {
              scale: '0.5',
              transformOrigin: 'center'
            }
          }),
          ...styles.flagIcon
        }}
      />
    ];
  }, [source, id]);

  return (
    <Box>
      <Stack
        direction='row'
        spacing='4px'
        alignItems='center'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        {flags}
        {showDetails && <InfoIconSummary sx={styles.infoIcon} />}
      </Stack>
      {showDetails && (
        <Popover
          sx={styles.popoverContainer}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <Box sx={styles.cardWrapper}>
            {isObject(source) ? (
              <Stack>
                {Object.keys(source).map(key => {
                  const IndividualFlag = sourceFlagMapping[key];
                  return (
                    <Box key={key}>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <IndividualFlag sx={styles.flagIcon} />
                        <Typography sx={styles.sourceTitle}>{getParentSourceLabel(key)}</Typography>
                      </Stack>
                      {source[key].map(value => (
                        <Typography key={value} sx={styles.sourceSubText}>
                          {DOCUMENT_SEARCH_SUBTEXT[value]}
                        </Typography>
                      ))}
                      <Box sx={styles.boxSpace} />
                    </Box>
                  );
                })}
              </Stack>
            ) : (
              <Typography>{DOCUMENT_SEARCH_SUBTEXT?.[source] ?? source}</Typography>
            )}
          </Box>
        </Popover>
      )}
    </Box>
  );
};

NotificationFlag.defaultProps = {
  id: 'icon',
  showDetails: false
};

export default NotificationFlag;
