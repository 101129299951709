import { Box, Typography, Button } from '@mui/material';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import styles from '../../../components/Header/styles/NotificationsPopup.styles';
import { CHANNELS, channelTypeTitles, redirectTabs } from '../constants';

const NoNotification = ({
  type,
  channelType
}: {
  type: 'subscription' | 'notification';
  channelType: (typeof CHANNELS)[number];
}) => {
  const history = useHistory();
  const location = useLocation();

  const channelTypeTile = useMemo(
    () => channelTypeTitles[channelType] ?? 'Notification',
    [channelType]
  );

  const redirectTab = useMemo(() => redirectTabs[channelType] ?? 'source', [channelType]);

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height={150}>
      <Typography align='center' fontSize='medium' sx={styles.noDataText}>
        {type === 'subscription' ? 'Nothing subscribed' : 'You do not have any notifications'}
      </Typography>
      <Typography align='center' fontSize='small' sx={styles.noDataText} fontStyle='italic'>
        <b>Subscribe to</b> <br /> {channelTypeTile}
      </Typography>
      {!location.pathname.includes('settings') && (
        <Button
          variant='text'
          onClick={() => history.push(`/notifications/settings?tab=${redirectTab}`)}>
          <Typography align='center' fontSize='small' sx={styles.noDataText} fontStyle='italic'>
            View Subscriptions
          </Typography>
        </Button>
      )}
    </Box>
  );
};
export default NoNotification;
