import {
  Box,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import EmailIcon from '../../../assets/svgs/Icons/EmailIcon';
import AuthContext from '../../../store/Auth/AuthProvider';
import notificationsStyles from '../styles/Notifications.styles';
import {
  getSubscriptionEmailPreference,
  updateSubscriptionEmailPreference,
  addSubscriptionEmailPreference
} from '../../../api/modules/userNotification';

// store
import Store from '../../../store';
import Actions from '../../../store/actions';
import SwitchWithLoader from '../../../components/Switch/SwitchWithLoader';

const EmailSettings = () => {
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(Store) as any;

  const [emailFrequency, setEmailFrequency] = useState('1');
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(true);
  const [frequencyLoading, setFrequencyLoading] = useState(true);

  const triggerChangeEmailSubscription = async (
    frequency: string,
    status: boolean,
    triggeredBy: 'status' | 'freuquency'
  ) => {
    if (triggeredBy === 'status') setToggleLoading(true);
    else setFrequencyLoading(true);
    try {
      const response = await updateSubscriptionEmailPreference(frequency, status);
      if (response?.status === 404) {
        const res = await addSubscriptionEmailPreference(frequency, status);
        if (res?.status !== 200) {
          throw new Error('Failed to add subscription email preference');
        }
      } else if (response?.status !== 200) throw new Error('Unexpected response status');

      setEmailFrequency(frequency);
      setIsEmailEnabled(status);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Error updating email preferences' }
      });
    } finally {
      setToggleLoading(false);
      setFrequencyLoading(false);
    }
  };

  const handleGetSubscriptionEmailPreference = async () => {
    setToggleLoading(true);
    setFrequencyLoading(true);
    try {
      const response = await getSubscriptionEmailPreference();
      if (response?.status === 200) {
        const { email_frequency: emailFreq, subscribed } = response.data.body;
        setEmailFrequency(emailFreq as string);
        setIsEmailEnabled(subscribed);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Error fetching email preferences' }
      });
    } finally {
      setToggleLoading(false);
      setFrequencyLoading(false);
    }
  };

  useEffect(() => {
    handleGetSubscriptionEmailPreference();
  }, []);

  return (
    <Stack sx={notificationsStyles.emailContainer}>
      <Box sx={notificationsStyles.emailHeader}>
        <EmailIcon sx={notificationsStyles.emailIcon} />
        <Typography>Email Settings</Typography>
      </Box>
      <List sx={notificationsStyles.emailList}>
        <ListItem
          secondaryAction={
            <SwitchWithLoader
              value={isEmailEnabled}
              checked={isEmailEnabled}
              loading={toggleLoading}
              onChange={e =>
                triggerChangeEmailSubscription(emailFrequency, e.target.checked, 'status')
              }
            />
          }>
          <ListItemText primary='Email to' secondary={currentUser?.email} />
        </ListItem>
        <ListItem
          sx={{
            mt: '16px'
          }}
          secondaryAction={
            <FormControl
              fullWidth
              variant='standard'
              disabled={!isEmailEnabled || frequencyLoading}>
              <Select
                sx={notificationsStyles.select}
                labelId='email-frequency-label'
                id='email-frequency'
                value={emailFrequency}
                onChange={e =>
                  triggerChangeEmailSubscription(e.target.value, isEmailEnabled, 'freuquency')
                }>
                <MenuItem value='1'>Daily</MenuItem>
                <MenuItem value='7'>Weekly</MenuItem>
                <MenuItem value='30'>Monthly</MenuItem>
              </Select>
            </FormControl>
          }>
          <ListItemText primary='Email frequency' />
        </ListItem>
      </List>
    </Stack>
  );
};
export default EmailSettings;
