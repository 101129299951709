import palette from '../../../themev5/palette';

const styles = {
  dialogPaper: {
    borderRadius: '8px'
  },
  dialogWrapper: {
    background: 'rgba(56, 56, 56, 0.40)'
  },
  dialogContent: { padding: '16px 32px 32px 32px !important' },
  mergeMenu: {
    p: '16px 24px 16px 16px !important'
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch'
  },
  closeButtonBox: {
    position: 'absolute',
    right: '12px',
    top: '12px'
  },
  closeButtonIcon: {
    width: '20px',
    height: '20px'
  },
  contentWrapper: {
    textAlign: 'center',
    alignItems: 'center'
  },
  titleText: {
    color: 'gray.800',
    fontSize: '20px',
    fontWeight: 700,
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center'
  },
  textSpan1: { fontSize: '14px' },
  textSpan2: { fontStyle: 'italic', mt: '8px' },
  finalKeywordsQueryBox: {
    alignSelf: 'center',
    backgroundColor: 'primary.0',
    padding: '12px 20px',
    borderRadius: '12px',
    my: '20px',
    display: 'inline-flex'
  },
  finalKeywordsQuery: {
    fontSize: '16px',
    fontWeight: 400,
    color: 'primary.600'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: '10px',
    mb: '16px',
    width: '64px'
  },
  subtitleText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'gray.900',
    textAlign: 'center',
    mx: '16px'
  },
  infoIcon: {
    width: '14px',
    height: '14px',
    color: 'gray.700'
  },
  keywordInput: {
    width: '100%',
    height: '48px',
    borderRadius: '8px',
    borderColor: 'gray.400',
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: '12px',
    fontSize: '16px',
    fontWeight: 400,
    color: 'gray.800',
    mt: '16px'
  },
  editButton: {
    color: 'primary.600',
    borderRadius: '24px',
    textTransform: 'none',
    borderColor: 'primary.600',
    height: '36px',
    minWidth: '100px',
    boxShadow: 'none',
    paddingX: '24px',
    fontSize: '14px',
    fontWeight: 600
  },
  subscribeButton: {
    color: 'white.main',
    textTransform: 'none',
    borderRadius: '24px',
    backgroundColor: 'primary.600',
    height: '36px',
    minWidth: '100px',
    boxShadow: 'none',
    paddingX: '24px',
    fontSize: '14px',
    fontWeight: 700
  },
  skeletonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: '20px'
  },
  keywordStackWrapper: { overflowY: 'auto', maxHeight: '400px', pt: '2px' },
  editBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '400px'
  },
  andButton: {
    width: 'auto',
    height: 'auto',
    p: '4px 6px !important',
    textAlign: 'center',
    color: `${palette.primary['500']} !important`,
    background: `${palette.primary.background} !important`,
    fontSize: '12px',
    fontWeight: 700,
    '&:disabled': {
      '& svg': {
        display: 'none'
      }
    },
    '& .MuiButton-endIcon': {
      mr: 0,
      ml: 0
    }
  },
  addNewRowButton: {
    mt: '24px'
  },
  removeIcon: {
    fontSize: 20
  }
};

export default styles;
