import React, { useEffect, useMemo } from 'react';

import useDocuments from '../../hooks/useDocuments';

// store
import getDateString from '../../../../utils/getDateString';
import DocumentListing from '../../../../components/Dialogs/DocumentListing';
import { DocumentsGroup } from '../../../../components/Dialogs/DocumentListing/types';

const FDADocuments: React.FC<any> = ({
  applicationNumber,
  submission,
  onClose,
  applicationDetails,
  source = 'us',
  approvalDate = ''
}: any) => {
  const { documents, getDocuments, isLoading } = useDocuments(
    applicationNumber,
    submission,
    source,
    approvalDate
  );

  useEffect(() => {
    getDocuments();
  }, [applicationNumber, submission]);

  const documentGroup: DocumentsGroup[] = useMemo(() => {
    const groups: DocumentsGroup[] = Object.keys(documents).map((groupName: string) => {
      const docs = documents[groupName].map((doc: { [key: string]: any }) => ({
        // eslint-disable-next-line no-underscore-dangle
        title: doc._category,
        // eslint-disable-next-line no-underscore-dangle
        title_link: doc.s3_path,
        chatRiaMetadata: {
          category: groupName
        }
      }));

      return {
        id: groupName,
        categoryTitle: groupName,
        documents: docs
      };
    });

    return groups;
  }, [documents]);

  const title =
    source === 'us'
      ? `${applicationNumber}-${submission}`
      : `${applicationNumber} - ${getDateString(approvalDate)}`;

  const chatRiaMetadata = {
    active_ingredient: `${applicationDetails?.generic_name || ''} (${
      applicationDetails?.product_name || ''
    })`,
    approval_date: applicationDetails.approval_date ?? '',
    application_number: applicationNumber,
    submission_id: submission
  };
  return (
    <DocumentListing
      title={title}
      defaultDocuments={documentGroup}
      onClose={onClose}
      chatRIAMetadata={chatRiaMetadata}
      source={source}
      defaultSelectedEntityId=''
      disableChatRia={false}
      secondaryDrawerTitle=''
      showLoading={isLoading}
    />
  );
};

export default React.memo(FDADocuments);
