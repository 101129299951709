const styles = {
  divider: {
    backgroundColor: 'gray.200',
    opacity: 'unset !important',
    borderColor: '#0000001f !important'
  },
  listItemText: { color: 'gray.500', marginLeft: 0.5 },
  listItemTextGrey: { color: 'gray.700' },
  subscribeIcon: {
    height: '16px',
    width: '16px',
    marginLeft: '10px'
  }
};

export default styles;
