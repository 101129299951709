import { createSvgIcon } from '@mui/material';

const AddCircleVariantIcon = createSvgIcon(
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' width='32' height='32' rx='16' fill='currentColor' />
    <path
      d='M9.38889 16.8889H15.6111V23.1111C15.6111 23.3469 15.7048 23.573 15.8715 23.7397C16.0382 23.9064 16.2643 24 16.5 24C16.7357 24 16.9618 23.9064 17.1285 23.7397C17.2952 23.573 17.3889 23.3469 17.3889 23.1111V16.8889H23.6111C23.8469 16.8889 24.073 16.7952 24.2397 16.6285C24.4064 16.4618 24.5 16.2357 24.5 16C24.5 15.7643 24.4064 15.5382 24.2397 15.3715C24.073 15.2048 23.8469 15.1111 23.6111 15.1111H17.3889V8.88889C17.3889 8.65314 17.2952 8.42705 17.1285 8.26035C16.9618 8.09365 16.7357 8 16.5 8C16.2643 8 16.0382 8.09365 15.8715 8.26035C15.7048 8.42705 15.6111 8.65314 15.6111 8.88889V15.1111H9.38889C9.15314 15.1111 8.92705 15.2048 8.76035 15.3715C8.59365 15.5382 8.5 15.7643 8.5 16C8.5 16.2357 8.59365 16.4618 8.76035 16.6285C8.92705 16.7952 9.15314 16.8889 9.38889 16.8889V16.8889Z'
      fill='white'
    />
  </svg>,
  'AddCircleVariant'
);

export default AddCircleVariantIcon;
