import React, { useEffect, useMemo } from 'react';

import useCTDocuments from '../../hooks/useCTDocuments';

// store
import DocumentListing from '../../../../components/Dialogs/DocumentListing';
import { DocumentsGroup } from '../../../../components/Dialogs/DocumentListing/types';

const CTDocuments: React.FC<any> = ({
  id,
  onClose,
  selectedGraphEntityData,
  source = 'ct'
}: any) => {
  const { ctDocuments, isLoading, getCTDocuments } = useCTDocuments(id, source);

  useEffect(() => {
    getCTDocuments();
  }, [id]);

  const documentGroup: DocumentsGroup[] = useMemo(() => {
    if (Array.isArray(ctDocuments) && ctDocuments.length === 0) {
      return [];
    }
    if (Array.isArray(ctDocuments)) {
      return [
        {
          id: 'documents',
          categoryTitle: 'Documents',
          documents: ctDocuments.map((doc: { [key: string]: any }) => ({
            // eslint-disable-next-line no-underscore-dangle
            title: doc.doc_type,
            // eslint-disable-next-line no-underscore-dangle
            title_link: doc.s3_path,
            chatRiaMetadata: {
              category: doc.doc_type
            }
          }))
        }
      ];
    }

    const groups: DocumentsGroup[] = Object.keys(ctDocuments).map((groupName: string) => {
      const docs = ctDocuments[groupName].map((doc: { [key: string]: any }) => ({
        // eslint-disable-next-line no-underscore-dangle
        title: doc._category,
        // eslint-disable-next-line no-underscore-dangle
        title_link: doc.s3_path,
        chatRiaMetadata: {
          category: groupName
        }
      }));

      return {
        id: groupName,
        categoryTitle: groupName,
        documents: docs
      };
    });

    return groups;
  }, [ctDocuments]);

  const chatRIAMetadata = {
    brief_title: selectedGraphEntityData.studyTitle ?? '',
    start_date: selectedGraphEntityData.startDate ?? '',
    phase: selectedGraphEntityData.phase ?? '',
    nct_id: id ?? ''
  };

  return (
    <DocumentListing
      title={id}
      defaultDocuments={documentGroup}
      onClose={onClose}
      chatRIAMetadata={chatRIAMetadata}
      source={source}
      defaultSelectedEntityId=''
      disableChatRia={false}
      secondaryDrawerTitle=''
      showLoading={isLoading}
    />
  );
};

export default React.memo(CTDocuments);
