import Box from '@mui/material/Box';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Button, CircularProgress, Dialog, DialogContent, Divider, Grid } from '@mui/material';
import { isArray, isEmpty, uniqueId } from 'lodash';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import { getGraphDataByConfig, getGraphSuggestions } from '../../api/modules/Reports';
import PieChart from '../CustomComponents/Graphs/PieChart';
import TableView from './TableView';

import Store from '../../store';
import Actions from '../../store/actions';
import BarChart from '../CustomComponents/Graphs/BarChart';
import DatapointPicker from './DataPicker';
import StyleComponent from './StyleComponent';
import { MODULE_AND_SOURCES } from '../Header/const';
import customTitleCase from '../../utils/titleCase';
import { getSortOnAxis } from './utils/getSortOnAxis';
import getStyleFromAppliedFilter from './utils/utils';
import { CHART_TYPE_TITLE_SUFFIX, MAX_TABLE_ROWS } from './constants';
import widgetStyles from './styles/widgets.styles';
import PieChartIcon from '../../assets/svgs/Report/PieChartIcon';
import BarChartIcon from '../../assets/svgs/Report/BarChartIcon';
import TableChartIcon from '../../assets/svgs/Report/TableChartIcon';
import ReportAction from './ReportAction';
import useReportingHandler from './hooks/useReportingHandler';
import PlusIcon from '../../assets/svgs/Report/PlusIcon';
import CaretDownIcon from '../../assets/svgs/Icons/CaretDown';
import CreateNewReportForm from './components/CreateNewReportForm';
import ReportLoader from './components/ReportLoader';

// eslint-disable-next-line no-unused-vars
const PRE_DEFINED_TEMPLATE_ID: any = {
  'pie-widget': {
    templates: [
      'generics_products__pie_chart__yes_no__eu',
      'biosimilars_products__pie_chart__yes_no__eu'
    ]
  },
  'bar-widget': {
    templates: [
      'patent_expiration__bar__quarter__us-fda--nda',
      'exclusivity_expiration__bar__quarter__us_fda--nda'
    ]
  }
};

export const STYLE_COMPONENTS: any = {
  'pie-widget': [
    {
      id: 'innerRadius',
      value: 0.1,
      label: 'Inner Radius',
      type: 'slider',
      group: 'slider',
      paramter: {
        step: 0.1,
        min: 0,
        max: 1
      }
    },
    {
      id: 'pieLegend',
      value: false,
      label: 'Legend',
      type: 'checkbox',
      group: 'legend',
      disabled: ['anchor', 'direction']
    },
    {
      id: 'anchor',
      value: 'bottom-right',
      label: 'Legend Position',
      type: 'dropdown',
      group: 'legend',
      disabled: 'pieLegend',
      options: [
        {
          key: 'Bottom Right',
          value: 'bottom-right'
        },
        {
          key: 'Bottom Left',
          value: 'bottom-left'
        },
        {
          key: 'Top Right',
          value: 'top-right'
        },
        {
          key: 'Top Left',
          value: 'top-left'
        }
      ]
    },
    {
      id: 'direction',
      value: 'column',
      label: 'Legend Format',
      type: 'dropdown',
      group: 'legend',
      disabled: 'pieLegend',
      options: [
        {
          key: 'Column',
          value: 'column'
        }
      ]
    },
    {
      id: 'pieLabel',
      value: true,
      label: 'Label',
      type: 'checkbox',
      group: 'label',
      disabled: ['enableLabels']
    },
    {
      id: 'enableLabels',
      value: 'both',
      label: 'Label Type',
      type: 'dropdown',
      group: 'label',
      disabled: 'pieLabel',
      options: [
        {
          key: 'In Chart',
          value: 'inchart'
        },
        {
          key: 'Arc Links',
          value: 'arc'
        },
        {
          key: 'Both (In Chart + Arc Links)',
          value: 'both'
        }
      ]
    },
    {
      id: 'colors',
      value: 'Default',
      label: 'Colors Schema',
      group: 'colors',
      type: 'multi-value-dropdown',
      customColor: false,
      options: [
        {
          key: 'Default',
          value: ['#48E0A6', '#6868FA', '#12815E']
        },
        {
          key: 'RIA Color',
          value: ['#6868FA', '#EA5252', '#29ABE2', '#0A467C', '#C9D2F6', '#B1BBE4']
        },
        {
          key: 'Sequential: Purple',
          value: ['#D8BFD8', '#E6E6FA', '#EE82EE', '#DA70D6', '#800080', '#4B0082']
        },
        {
          key: 'Sequential: Red',
          value: ['#FFC0CB', '#FF69B4', '#FF1493', '#DB7093', '#FF0000', '#B22222']
        },
        {
          key: 'Sequential: Blue',
          value: ['#ADD8E6', '#87CEEB', '#00BFFF', '#1E90FF', '#0000FF', '#00008B']
        },
        {
          key: 'Sequential: Green ',
          value: ['#3AB09E', '#289D78', '#A1D99B', '#238B45', '#119E8A']
        }
      ]
    }
  ],
  'bar-widget': [
    {
      id: 'padding',
      value: 0.3,
      label: 'Spacing',
      type: 'slider',
      group: 'slider',
      paramter: {
        step: 0.1,
        min: 0,
        max: 1
      }
    },
    {
      id: 'tickRotationX',
      value: 85,
      group: 'slider',
      label: 'Rotate X-Axis Label',
      type: 'slider',
      paramter: {
        step: 15,
        min: 0,
        max: 90
      }
    },
    {
      id: 'tickRotationY',
      value: 0,
      group: 'slider',
      label: 'Rotate Y-Axis Label',
      type: 'slider',
      paramter: {
        step: 15,
        min: 0,
        max: 90
      }
    },
    {
      id: 'layout',
      value: 'vertical',
      label: 'Layout',
      group: 'layout',
      type: 'radio',
      options: [
        {
          key: 'Horizontal',
          value: 'horizontal'
        },
        {
          key: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    {
      id: 'colors',
      value: 'Default',
      label: 'Color Scheme',
      group: 'colors',
      type: 'multi-value-dropdown',
      customColor: false,
      options: [
        {
          key: 'Default',
          value: ['#48E0A6', '#6868FA', '#12815E']
        },
        {
          key: 'Purple',
          value: ['#B1BBE4']
        },
        {
          key: 'Green',
          value: ['#12815E']
        },
        {
          key: 'Blue',
          value: ['#6868FA']
        },
        {
          key: 'Sequential: Purple',
          value: ['#D8BFD8', '#E6E6FA', '#EE82EE', '#DA70D6', '#800080', '#4B0082']
        },
        {
          key: 'Sequential: Red',
          value: ['#FFC0CB', '#FF69B4', '#FF1493', '#DB7093', '#FF0000', '#B22222']
        },
        {
          key: 'Sequential: Blue',
          value: ['#ADD8E6', '#87CEEB', '#00BFFF', '#1E90FF', '#0000FF', '#00008B']
        },
        {
          key: 'Sequential: Green ',
          value: ['#3AB09E', '#289D78', '#A1D99B', '#238B45', '#119E8A']
        }
      ]
    }
  ]
};

const styles = {
  widgetItem: {
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  plusIcon: {
    fontSize: 13
  },
  reportText: {
    color: 'gray.0',
    fontWeight: 600,
    fontSize: 14,
    alignItems: 'center',
    display: 'inline-flex',
    textTransform: 'capitalize',
    gap: 1,
    '&:hover': {
      background: 'none'
    }
  },
  divider: {
    width: 2,
    backgroundColor: 'gray.0',
    ml: 1
  },
  caretIcon: {
    fontSize: 16,
    color: 'gray.0'
  }
};

const WIDGET_COMPONENTS: any = {
  'pie-widget': PieChart,
  'bar-widget': BarChart,
  'table-widget': TableView
};

const MODULE_LABEL: any = {
  ema_guidance: 'EMA Guidance',
  canada_guidance: 'CA Guidance',
  fda_guidance: 'FDA Guidance',
  canada_consultation: 'CA Consultation',
  fda_written_requests: 'FDA Pediatrics',
  ema_pips: 'EMA Pediatrics',
  adcomm: 'Advisory Committee',
  'adcomm-cdrh': 'Advisory Committee',
  'adcomm-cber': 'Advisory Committee',
  chmp: 'CHMP',
  hta: 'HTA',
  impact: 'PMR Impact',
  surveillance: 'PMR Surveillance',
  fda_letters: 'FDA Warnings and Untitled Letters',
  'dd-tools': 'Drug Development Tools',
  pediatrics: 'EU Clinical Trials',
  ema_orphan: 'EMA Orphan Designations',
  pdco: 'PDCO',
  prac: 'PRAC'
};

const getChartType = (widgetId: string) => {
  return widgetId.split('-')[0]?.toUpperCase();
};

export const getReferenceText = (refMetadata: any, refDataSource: any) => {
  if (!refMetadata) {
    return null;
  }
  let referenceText = '';
  if (refMetadata.module in MODULE_AND_SOURCES) {
    // @ts-ignore
    const sourceMetadata = MODULE_AND_SOURCES[refMetadata.module].filter(
      (elem: any) =>
        elem.db === refMetadata.source || elem.autoCompleteAPIParam === refMetadata.source
    )[0];
    referenceText += sourceMetadata.label;
    if (refMetadata.searchType) {
      const searchType =
        refMetadata.searchType === 'quicksearch' ? 'Generic Search' : 'Advance Search';
      if (searchType === 'Generic Search') {
        const searchText = customTitleCase(refMetadata.search);
        referenceText += `  / ${searchText} ${
          refMetadata.isFiltersApplied ? '(with Filters)' : ''
        }`;
      }
    } else if (refMetadata?.application_number) {
      referenceText += ` / Application: ${refMetadata.application_number} / Reg360 ${
        refMetadata.isFiltersApplied ? '(with Filters)' : ''
      }`;
    }
    return referenceText;
  }
  referenceText += `${MODULE_LABEL[refMetadata.module]}`;
  if (!isEmpty(refDataSource?.filters)) {
    Object.keys(refDataSource.filters).forEach((filterKey: any) => {
      referenceText += ` / ${refDataSource?.filterLabelList[filterKey]}: ${
        isArray(refDataSource.filters[filterKey])
          ? refDataSource.filters[filterKey].join(', ')
          : refDataSource.filters[filterKey]
      }`;
    });
  }
  return referenceText;
};

const WidgetModal = ({ widgetId, widgetDataSource = null, open, onClose, pathMetaData }: any) => {
  const { state, dispatch } = useContext<any>(Store);
  const [graphsData, setGraphsData]: any = useState();
  // eslint-disable-next-line no-unused-vars
  const [templateId, setTemplateId] = useState('');
  const [titleSetting, setTitleSettings] = useState<any>({});
  const [stylesFilters, setStyleFilters] = useState<any>({});
  const [isStylesFiltersApplied, setIsStylesFiltersApplied] = useState<boolean>(false);
  const [refnsSetting, setReferenceSettings] = useState<any>({ ...pathMetaData, show: true });
  const [sourceProperty, setSourceProperty] = useState<any>({ ...widgetDataSource });

  const [properties, setProperties] = useState<any>([]);
  const [property, setProperty] = useState<any>([]);
  const [isRiaGraphEnabled, setIsRiaGraphEnabled] = useState<boolean>(false);
  const [riaGraphSelected, setRiaGraphSelected] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [limit, setLimit] = useState('10');
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [anchorElButton, setAnchorElButton] = React.useState<null | HTMLElement>(null);
  const { updateReportData, fetchActiveReportDetails } = useReportingHandler();
  const [openCreateNewReportDialog, setOpenCreateNewReportDialog] = useState<boolean>(false);

  useEffect(() => {
    if (['pie-widget', 'bar-widget'].includes(widgetId)) {
      const styleObject = getStyleFromAppliedFilter(STYLE_COMPONENTS[widgetId]);
      setSourceProperty({
        ...sourceProperty,
        graphStyle: styleObject
      });
      setStyleFilters(styleObject);
    }
  }, [widgetId]);

  const handleStyleChange = useCallback(
    (styleList: any) => {
      setSourceProperty({
        ...sourceProperty,
        graphStyle: styleList
      });
      setIsStylesFiltersApplied(true);
      setStyleFilters(styleList);
    },
    [sourceProperty]
  );

  const handleTabChange = useCallback(
    (newValue: number) => {
      setSelectedTab(newValue);
    },
    [sourceProperty]
  );

  const getGraphDataBySourceConfig = async (config: any) => {
    try {
      const chartType = getChartType(widgetId).toLowerCase();
      let query = {};

      if (chartType === 'table') {
        query = {
          columns: config.property,
          agg_function: config.aggFunc,
          ...(config.showLimit ? { limit: config.limit } : {})
        };
      } else {
        query = {
          'x-axis': config.property,
          agg_function: config.aggFunc,
          'y-axis': config.yAxis,
          sort_on_axis: getSortOnAxis(config.property, chartType),
          ...(config.showLimit ? { limit: parseInt(config.limit, 10) } : {})
        };
      }
      const payload = {
        source: config.source,
        search_type: config.moduleName || 'quicksearch',
        search_id: config.searchId ? config.searchId : undefined,
        application_number: config.applicationNumber ? config.applicationNumber : undefined,
        filters: config.filters,
        query
      };
      const response = await getGraphDataByConfig(chartType, payload);
      if (
        response &&
        response.data.Success !== '' &&
        Object.keys(response.data.Success.data).length !== 0
      ) {
        setGraphsData(response.data.Success);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleReportClick = (e: any) => {
    setAnchorElButton(e.currentTarget);
    setOpenReport(true);
  };

  const handleAddToReportId = async (reportData: any) => {
    dispatch({
      type: Actions.REPORT_SAVING,
      value: true
    });
    const chartType = getChartType(widgetId).toLowerCase();
    let query = {};
    if (chartType === 'table') {
      query = {
        columns: sourceProperty.property,
        agg_function: sourceProperty.aggFunc,
        ...(sourceProperty.showLimit ? { limit: sourceProperty.limit } : {})
      };
    } else {
      query = {
        'x-axis': sourceProperty.property,
        agg_function: sourceProperty.aggFunc,
        'y-axis': sourceProperty.yAxis,
        sort_on_axis: getSortOnAxis(sourceProperty.property, chartType),
        ...(sourceProperty.showLimit ? { limit: sourceProperty.limit } : {})
      };
    }
    const sectionLayout = {
      chartType: getChartType(widgetId),
      templateType: 'custom',
      data: {
        query,
        search_id: sourceProperty.searchId ? sourceProperty.searchId : undefined,
        search_type: sourceProperty.moduleName || 'quicksearch',
        source: sourceProperty.source,
        application_number: sourceProperty.applicationNumber
          ? sourceProperty.applicationNumber
          : undefined,
        ...(sourceProperty.filters ? { filters: sourceProperty.filters } : {})
      },
      id: reportData?.reportId,
      sectionType: 'CHART',
      style: {
        placement: {
          h: 40,
          w: 12,
          i: reportData?.reportId,
          minW: 8,
          moved: false,
          static: false,
          x: 0,
          y: 0
        },
        title: titleSetting,
        graphStyle: sourceProperty.graphStyle,
        references: refnsSetting
      }
    };

    updateReportData(reportData?.id, sectionLayout);
  };

  const handleAddToActiveReport = async () => {
    dispatch({
      type: Actions.REPORT_SAVING,
      value: true
    });
    const activeReportData = await fetchActiveReportDetails();
    if (Object.keys(activeReportData).length === 0) {
      setOpenCreateNewReportDialog(true);
      dispatch({
        type: Actions.REPORT_SAVING,
        value: false
      });
    } else {
      const random = Math.floor(Math.random() * 1000);
      const id = uniqueId(`report_customization_${random}_`);
      handleAddToReportId({ ...activeReportData, reportId: id });
      await dispatch({
        type: Actions.SET_CART_ITEMS,
        value: state.cartItems + 1
      });
    }
  };

  const handleSubmit = (reportDetails: any) => {
    const random = Math.floor(Math.random() * 1000);
    const id = uniqueId(`report_customization_${random}_`);
    handleAddToReportId({ ...reportDetails, reportId: id });
    setOpenCreateNewReportDialog(false);
  };

  const handleDataSourceChanges = (updateSourceProperty: any) => {
    setSourceProperty({
      ...sourceProperty,
      graphStyle: sourceProperty.graphStyle,
      ...updateSourceProperty
    });
    getGraphDataBySourceConfig({
      ...sourceProperty,
      ...updateSourceProperty
    });
  };

  const handleRiaGraphChanges = useCallback(
    (config: any) => {
      const updatedConfig = {
        ...sourceProperty,
        ...config
      };
      setSourceProperty({
        ...sourceProperty,
        graphStyle: stylesFilters,
        ...config
      });
      getGraphDataBySourceConfig(updatedConfig);
    },
    [sourceProperty, stylesFilters]
  );
  const handleReferenceConfigSettings = (config: any) => {
    setReferenceSettings({
      ...refnsSetting,
      ...config,
      text: getReferenceText({ ...config.metadata, source: config.source }, widgetDataSource)
    });
  };

  const getPropertyOptions = async () => {
    const moduleName = sourceProperty.moduleName || 'quicksearch';
    const graphType = getChartType(widgetId).toLowerCase();
    const source = sourceProperty.source || 'us';
    const aggFunc = sourceProperty.aggFunc || 'count';
    const yAxis: any[] = [];
    const showLimit = graphType === 'pie' || graphType === 'bar';
    let styleObject: any = {};
    if (['pie-widget', 'bar-widget'].includes(widgetId)) {
      styleObject = getStyleFromAppliedFilter(STYLE_COMPONENTS[widgetId]);
    }

    const response: any = await getGraphSuggestions(source, graphType, moduleName);
    if (response && response.data.Success) {
      // for table, if no column is having identifier as true / anchor column make the 1st colums as anchor column
      if (widgetId === 'table-widget') {
        const anchors = response.data.Success.suggestion.filter((col: any) => col.identifier);
        if (anchors.length === 0) {
          response.data.Success.suggestion[0].identifier = true;
        }
      }
      setProperties(response.data.Success.suggestion);
      let selected: any;
      if (widgetId === 'table-widget') {
        const anchorColumns = response.data.Success.suggestion.filter((col: any) => col.identifier);
        const normalColumns = response.data.Success.suggestion.filter(
          (col: any) => !col.identifier
        );
        const normalColIds = normalColumns.map((col: any) => col.id);
        if (
          sourceProperty.property &&
          sourceProperty.property.every((element: string) => normalColIds.includes(element))
        ) {
          selected = sourceProperty.property;
        } else if (anchorColumns && anchorColumns.length > 0) {
          const anchorColIds = anchorColumns.map((col: any) => col.id);
          selected = [...anchorColIds, ...normalColIds.slice(0, 5)];
        } else {
          selected = [...normalColIds.slice(0, 5)];
        }
      } else {
        let propertyPresent: boolean = false;
        if (sourceProperty.property) {
          const propertykey = response.data.Success.suggestion.some(
            (obj: any) => obj.id === sourceProperty.property[0]
          );
          if (propertykey) {
            propertyPresent = true;
          }
        }

        selected =
          sourceProperty.property && propertyPresent
            ? sourceProperty.property
            : [response.data.Success.suggestion[0].id];
      }
      const propertySuggestions = response.data.Success.suggestion;
      const propTitle = propertySuggestions.find((item: any) => item.id === selected[0]);
      const titleSuffix = CHART_TYPE_TITLE_SUFFIX[widgetId];
      setTitleSettings({
        show: true,
        title: propTitle ? `${propTitle.label} ${titleSuffix}` : '',
        fontSize: 20
      });
      setProperty(selected);
      handleDataSourceChanges({
        source,
        property: selected,
        aggFunc,
        yAxis,
        showLimit,
        limit: parseInt(limit, 10),
        graphStyle: styleObject
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Failed to get the suggestions');
    }
  };
  useEffect(() => {
    getPropertyOptions();
  }, [sourceProperty?.source]);

  const showLimitMessage =
    graphsData?.data?.data?.length === MAX_TABLE_ROWS && widgetId === 'table-widget';

  const WidgetComponent = WIDGET_COMPONENTS[widgetId];

  return (
    <Dialog open={open} disableAutoFocus={!open} fullWidth sx={widgetStyles.dialogBox}>
      {state?.reportSaving && (
        <ReportLoader open={state?.reportSaving} message='Saving to your report. Please wait...' />
      )}
      <DialogContent sx={{ overflow: 'hidden', p: '0px' }}>
        <Grid container lg={12} alignItems='center' sx={{ width: '100%' }}>
          {property.length === 0 ? (
            <Grid item lg={12} sx={widgetStyles.gridContainer}>
              <CircularProgress value={60} size={90} thickness={5} sx={{ color: '#adadac' }} />
            </Grid>
          ) : (
            <>
              <Grid item lg={6}>
                <Box sx={{ height: widgetId === 'bar-widget' ? 500 : 400 }}>
                  {graphsData?.data?.data ? (
                    <WidgetComponent
                      data={graphsData?.data?.data}
                      title={titleSetting}
                      yAxis={graphsData?.yAxis}
                      columnNameMapping={graphsData?.data?.attribute_name}
                      order={sourceProperty.property}
                      style={sourceProperty.graphStyle}
                      referenceText={refnsSetting}
                      fromWidget
                    />
                  ) : (
                    <Grid
                      item
                      lg={12}
                      sx={{
                        display: 'flex',
                        height: '400px',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}>
                      <CircularProgress
                        value={60}
                        size={90}
                        thickness={5}
                        sx={{ color: '#adadac' }}
                      />
                    </Grid>
                  )}
                  {showLimitMessage && (
                    <Typography pl={2} fontSize={12} fontStyle='italic'>
                      *Maximum {MAX_TABLE_ROWS} rows can be added to report using table view.
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} sx={widgetStyles.selectGrid}>
                {property.length > 0 && (
                  <Box>
                    <Box p={2} sx={widgetStyles.headingContainer}>
                      <Typography sx={widgetStyles.headingText}>Create Chart:</Typography>
                      <Tooltip title='Close'>
                        <IconButton size='small' sx={widgetStyles.closeButton} onClick={onClose}>
                          <Close fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box sx={widgetStyles.propertyContainer}>
                      <Box display='flex' alignItems='flex-start' gap={2}>
                        <Box
                          onClick={() => handleTabChange(0)}
                          sx={{
                            ...widgetStyles.tabHeader,
                            color: selectedTab === 0 ? 'gray.900' : 'gray.700',
                            borderBottom: selectedTab === 0 ? '2px solid #2DAD9B' : 'none',
                            fontWeight: selectedTab === 0 ? 700 : 500
                          }}>
                          Data
                        </Box>
                        <Divider orientation='vertical' sx={widgetStyles.divider} />
                        {['pie-widget', 'bar-widget'].includes(widgetId) ? (
                          <Box
                            onClick={() => handleTabChange(1)}
                            sx={{
                              ...widgetStyles.tabHeader,
                              color: selectedTab === 1 ? 'gray.900' : 'gray.700',
                              borderBottom: selectedTab === 1 ? '2px solid #2DAD9B' : 'none',
                              fontWeight: selectedTab === 1 ? 700 : 500
                            }}>
                            Styles
                          </Box>
                        ) : null}
                      </Box>
                      {property.length > 0 && selectedTab === 0 && (
                        <Box mt={4} sx={{ overflow: 'auto', width: '100%' }}>
                          <DatapointPicker
                            defaultDataSource={widgetDataSource}
                            referenceMetaData={refnsSetting}
                            onTitleSettingChanges={setTitleSettings}
                            graphType={getChartType(widgetId).toLowerCase()}
                            onSourceSettingsChanges={handleDataSourceChanges}
                            onReferenceConfigChanges={handleReferenceConfigSettings}
                            widgetId={widgetId}
                            handleRiaGraphChanges={handleRiaGraphChanges}
                            properties={properties}
                            property={property}
                            setProperty={setProperty}
                            titleSetting={titleSetting}
                            riaGraphSelected={riaGraphSelected}
                            setRiaGraphSelected={setRiaGraphSelected}
                            isRiaGraphEnabled={isRiaGraphEnabled}
                            setIsRiaGraphEnabled={setIsRiaGraphEnabled}
                            limit={limit}
                            setLimit={setLimit}
                            setStyleFilters={setStyleFilters}
                            stylesFilters={stylesFilters}
                            isStylesFiltersApplied={isStylesFiltersApplied}
                          />
                        </Box>
                      )}
                      {property.length > 0 &&
                        selectedTab === 1 &&
                        ['pie-widget', 'bar-widget'].includes(widgetId) && (
                          <Box mb={5} sx={{ overflow: 'auto', width: '100%' }}>
                            <StyleComponent
                              styleList={STYLE_COMPONENTS[widgetId]}
                              handleStyleChange={handleStyleChange}
                              stylesFilters={stylesFilters}
                            />
                          </Box>
                        )}
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      gap={2}
                      sx={widgetStyles.buttonContainer}>
                      <Button onClick={onClose} sx={widgetStyles.reportCloseButton}>
                        Close
                      </Button>
                      <Box display='flex' flexDirection='row' sx={widgetStyles.reportSubmitButton}>
                        <Box
                          display='flex'
                          pl={1.5}
                          gap={1}
                          justifyContent='flex-start'
                          alignItems='center'
                          onClick={handleAddToActiveReport}>
                          <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                            <PlusIcon sx={styles.plusIcon} /> Report
                          </Typography>
                        </Box>
                        <Divider orientation='vertical' sx={styles.divider} />
                        <Box display='flex' p={1} alignItems='center' onClick={handleReportClick}>
                          <CaretDownIcon sx={styles.caretIcon} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            </>
          )}
          {openReport && (
            <ReportAction
              open={openReport}
              anchorEl={anchorElButton}
              handleAddToReportId={handleAddToReportId}
              handleClose={() => {
                setOpenReport(false);
              }}
            />
          )}
          {openCreateNewReportDialog && (
            <CreateNewReportForm
              openCreateNewReportDialog={openCreateNewReportDialog}
              setOpenCreateNewReportDialog={setOpenCreateNewReportDialog}
              handleSubmit={handleSubmit}
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ReportWidgets = ({
  widgets = ['free-text', 'pie-widget', 'bar-widget', 'table-widget'],
  dataSource = null,
  pathMetaData = {},
  setReportWidgetClose
}: any) => {
  const [widgetId, setWidgetId] = useState('');

  const { dispatch } = useContext<any>(Store);

  const handleChartClose = () => {
    setWidgetId('');
    setReportWidgetClose(null);
  };

  const handleTextWidgetAdd = useCallback(() => {
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        dataSource: 'custom',
        sectionType: 'TEXT',
        textType: 'HTML_TEXT',
        id: uniqueId(`report_widget_${random}_`),
        style: {
          placement: {
            h: 10,
            w: 12
          }
        },
        content: '<h3>Enter your text here...</h3>'
      }
    });
  }, []);

  return (
    <Box sx={widgetStyles.chartPopover}>
      {widgets.includes('free-text') && (
        <Box onClick={handleTextWidgetAdd}>
          <Tooltip title='Free Text'>
            <ArticleIcon sx={{ fontSize: 56, color: '#33B187', ...styles.widgetItem }} />
          </Tooltip>
        </Box>
      )}
      {widgets.includes('pie-widget') && (
        <Box onClick={() => setWidgetId('pie-widget')} sx={widgetStyles.chartIcon}>
          <PieChartIcon sx={{ fontSize: 15 }} />
          <Typography fontSize={14}>Pie Chart</Typography>
        </Box>
      )}
      {widgets.includes('bar-widget') && (
        <Box onClick={() => setWidgetId('bar-widget')} sx={widgetStyles.chartIcon}>
          <BarChartIcon sx={{ fontSize: 15 }} />
          <Typography fontSize={14}>Bar Chart</Typography>
        </Box>
      )}
      {widgets.includes('table-widget') && (
        <Box onClick={() => setWidgetId('table-widget')} sx={widgetStyles.chartIcon}>
          <TableChartIcon sx={{ fontSize: 15 }} />
          <Typography fontSize={14}>Table Chart</Typography>
        </Box>
      )}

      {widgetId && (
        <WidgetModal
          widgetId={widgetId}
          open={!!widgetId}
          onClose={handleChartClose}
          widgetDataSource={dataSource}
          pathMetaData={{
            text: getReferenceText(pathMetaData, dataSource),
            href: pathMetaData?.href,
            metadata: pathMetaData
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(ReportWidgets);
