/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'lodash';

import SearchIcon from '@mui/icons-material/Search';
import styles from '../../Home/styles/SearchBar.styles';
import useSearchSuggestions from '../../Home/hooks/useSearchSuggestions';
import { getAriaSearchWithSearchId, getGenericSearch } from '../../../api/pages/ResultsPage';
import SearchBarAutoComplete from '../../Home/components/SearchBarAutoComplete';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import GlobalActions from '../../../store/actions';
import AuthContext from '../../../store/Auth/AuthProvider';
import DocumentsView from './DocumentsView';
import NewFilters from '../../../components/NewFilters';
import { Category } from '../../../components/NewFilters/types';
import { AriaDrawerProps } from '../types/ariaDrawer.types';
import RESULT_VIEW_TYPES from './constants';
import getUserDetails from '../utils/getUserDetails';
import GlobalStore from '../../../store';
import { formatDocumentResults } from '../utils/documentResultsUtils';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import Loading from './Loading';
import { SourceDropdown } from '../../Home/types';
import { prepareAPIPayload } from '../utils/searchUtils';

const AriaDrawer = ({
  applications,
  sources,
  modalOpen,
  setModalOpen,
  filters,
  getFilters,
  globalDispatch,
  isCount = false,
  DocCount = 0,
  PageCount = 0,
  viewType,
  triggeredFromUserProfile = false
}: AriaDrawerProps) => {
  const history = useHistory();
  const location = useLocation();

  const { payload }: any = useParams();
  const [documentCount, setDocumentCount] = useState(0);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { state } = useContext(GlobalStore) as any;
  const { currentUser } = useContext(AuthContext);
  const [ariaViewType, setAriaViewType] = useState(viewType);
  const [applicationFilters, setApplicationFilters] = useState(null);
  const [newSearch, setNewSearch] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);

  const sourceDropDownAnchorEl: null | HTMLElement = null;
  const sourceDropDownOpen = Boolean(sourceDropDownAnchorEl);
  const [retainFilter, setRetainFilter] = useState(false);
  const [retainFilterApplied, setRetainFilterApplied] = useState(false);
  const [disableRetainFilter, setDisableRetainFilter] = useState(false);

  const onRetainFilterChange = (e: any) => {
    setRetainFilter(e.target.checked);
    if (!e.target.checked) {
      setRetainFilterApplied(true);
    }
  };

  const isReg360 = () => {
    return window.location.pathname.includes('/regulatory360');
  };

  const handleApplyFilters = (selectedFilter = {}) => {
    setSelectedFilters(selectedFilter);
    if (Object.keys(selectedFilter).length === 0) {
      setRetainFilter(false);
      setDisableRetainFilter(true);
    } else {
      setDisableRetainFilter(false);
    }

    // @ts-ignore
    getFilters(selectedFilter);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setShowDocumentsModal(false);
    globalDispatch({
      type: GlobalActions.TRIGGER_CHATRIA_CLOSE_SESSION,
      value: !state.chatRiaCloseSessionTrigger
    }); // closing ChatRIA session if any
    globalDispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: true });
    resultsDispatch({
      type: ResultActions.SET_IS_ARIA_OPENED,
      value: false
    });
    resultsDispatch({
      type: ResultActions.SET_VIEW_TYPE,
      value: ariaViewType
    });
    resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
    resultsDispatch({
      type: ResultActions.SET_ORIGINAL_TEXT_ENABLED,
      value: false
    });
    resultsDispatch({
      type: ResultActions.SET_FILTERS,
      value: applicationFilters
    });
    resultsDispatch({
      type: ResultActions.SET_ARIA_ENTITY_IDS,
      value: []
    });
    resultsDispatch({
      type: ResultActions.SET_ARIA_FILTERS,
      value: {}
    });
    setApplicationFilters(null);

    if (isReg360() || triggeredFromUserProfile) {
      const queryParams = new URLSearchParams(window.location.search);

      if (queryParams.has('ariaShare')) {
        queryParams.delete('ariaShare');
        history.replace({
          search: queryParams.toString()
        });
      }

      return;
    }

    const searchPayload: any = decodeBase64ToObject(payload);
    // reset the decryped payload state to original search details when aria drawer is closed
    // this is required for sharing search in collaborate
    const { view_type: payloadViewType } = searchPayload;
    const API_PAYLOAD = prepareAPIPayload(
      searchPayload,
      payloadViewType,
      resultsState,
      currentUser
    );
    resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: API_PAYLOAD });

    const newPayload = {
      ...searchPayload,
      view_type: ariaViewType,
      ...(searchPayload?.isAriaOpen && { isAriaOpen: false }),
      ...(searchPayload?.ariaSearchTerm && { ariaSearchTerm: '' }),
      ...(searchPayload?.ariaSearchId && { ariaSearchId: '' })
    };

    const encodedPayload: any = encodeObjectToBase64(newPayload);
    const previousEncodedPayload = window.location.pathname.split('/search/')[1];
    if (previousEncodedPayload !== encodedPayload) {
      const newPath = window.location.pathname.replace(previousEncodedPayload, encodedPayload);
      history.push(newPath);
    }
  };

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const check505b2 = () => {
    return Object.keys(sources).includes('us') && sources?.us?.includes('505b2');
  };

  const is505b2 = check505b2();

  const checkCT = () => {
    return (
      Object.keys(sources).includes('ct') &&
      (resultsState?.viewType === RESULT_VIEW_TYPES.CT ||
        (triggeredFromUserProfile && Object.keys(sources).length === 1))
    );
  };
  const isCT = checkCT();

  const checkEuCt = () => {
    return (
      Object.keys(sources).includes('eu') &&
      (resultsState?.viewType === RESULT_VIEW_TYPES.CT ||
        (triggeredFromUserProfile && sources.eu.includes('euct')))
    );
  };

  const isEuCt = checkEuCt();

  const getSourceForAria = () => {
    if (is505b2) {
      return { us: ['sba'] };
    }

    if (isCT) {
      return { ct: ['usnlm'] };
    }

    const nonCtAriaSources = { ...sources };
    delete nonCtAriaSources.ct;

    return nonCtAriaSources;
  };

  const getFinalFilters = (ariaFilters: any) => {
    if ((retainFilterApplied && !retainFilter) || newSearch) {
      setRetainFilterApplied(false);
      setSelectedFilters({});
      setDisableRetainFilter(true);
      return { ...ariaFilters };
    }
    if (resultsState?.ariaFilters?.entity_id?.length > 0) {
      return resultsState?.ariaFilters;
    }

    return { ...resultsState?.ariaFilters, ...ariaFilters };
  };

  const handleClearSearch = () => {
    resultsDispatch({
      type: ResultActions.SET_DOCUMENT_RESULTS,
      value: []
    });
  };

  const handleTextChange = (searchValue: string) => {
    setSearchText(searchValue);
    if (!retainFilter) {
      setNewSearch(true);
    }
  };

  const getEntityIds = () => {
    if (isCT && !triggeredFromUserProfile) {
      return resultsState?.ctFilters?.nct_id ?? [];
    }

    if (isEuCt) {
      return resultsState?.ctFilters?.euct_id ?? [];
    }

    return applications.map((data: any) => {
      if (data.source_index === 'ema') {
        if (data.center?.toLowerCase() === 'ema-who') return `EMEA-H-W-${data.identifier}`;
        return `EMEA-H-C-${data.identifier}`;
      }
      if (data.source === 'eu' && data.center?.toLowerCase() === 'ema-who')
        return `EMEA-H-W-${data.application_number || data.product_number || ''}`;
      if (data.source === 'eu' && data.center?.toLowerCase() !== 'ema-who')
        return `EMEA-H-C-${data.application_number || data.product_number || ''}`;
      if (data.identifier) {
        return data.identifier;
      }
      if (data.nct_id) {
        return data.nct_id;
      }
      if (data.euct_id) {
        return data.euct_id;
      }
      if (data.designation_number) {
        return data.designation_number;
      }
      return data.application_number;
    });
  };

  const handleSearch = useCallback(
    async (searchTextProp: string | null = null, searchId: string | null = null) => {
      const queryText = searchTextProp ?? searchText;
      if (isEmpty(queryText)) {
        return;
      }
      setIsLoading(true);
      try {
        const userDetails = getUserDetails(currentUser);
        const ariaFilters: any = {
          entity_id: getEntityIds()
        };

        if (is505b2) {
          ariaFilters['approval-pathway'] = ['505(b)(2)'];
        }
        let search_operation_id = null;
        switch (resultsState?.viewType) {
          case RESULT_VIEW_TYPES.APPLICATION:
            search_operation_id = resultsState.searchIds.applicationSearchId;
            setAriaViewType(RESULT_VIEW_TYPES.APPLICATION);
            break;
          case RESULT_VIEW_TYPES.CT:
            search_operation_id = resultsState.searchIds.ctSearchId;
            setAriaViewType(RESULT_VIEW_TYPES.CT);
            break;
          default:
            search_operation_id = null;
        }

        const API_PAYLOAD: any = {
          source: getSourceForAria(),
          query: queryText,
          view_type: 'document',
          filterSource: 'default',
          userDetails,
          filters: getFinalFilters(ariaFilters),
          search_operation_id
        };
        if (location.pathname.match('saved')) {
          API_PAYLOAD.search_from_favorites = true;
        } else if (location.pathname.match('project')) {
          const projectId = location.pathname.split('/')?.[3] ?? '';
          API_PAYLOAD.search_project_id = projectId;
        }
        let res: any = null;
        // Resetting the selected document to avoid showing the previous document
        resultsDispatch({
          type: ResultActions.SET_SELECTED_DOCUMENT,
          value: null
        });
        if (searchId) {
          res = await getAriaSearchWithSearchId({ searchId });
        } else {
          res = await getGenericSearch(API_PAYLOAD);
        }

        if (res?.status === 200) {
          resultsDispatch({
            type: ResultActions.SET_DOCUMENT_RESULTS,
            value: formatDocumentResults(res?.data?.body?.result)
          });
          if (searchId) {
            const resultFilters = res?.data?.body?.result?.filters?.checked_filter ?? {};
            setSelectedFilters(resultFilters);
          }
          resultsDispatch({
            type: ResultActions.SET_IS_ARIA_OPENED,
            value: true
          });
          resultsDispatch({ type: ResultActions.SET_DECRYPTED_SOURCE, value: sources });
          resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: API_PAYLOAD });
          resultsDispatch({
            type: ResultActions.SET_SEARCH_IDS,
            value: {
              ...resultsState.searchIds,
              documentSearchId: res?.data?.body?.search_id || search_operation_id,
              ariaSearchId:
                res?.data?.body?.view_type_metadata?.search_id || res?.data?.body?.search_id || ''
            }
          });
          resultsDispatch({
            type: ResultActions.SET_ARIA_ENTITY_IDS,
            value: ariaFilters.entity_id
          });
          setShowDocumentsModal(true);
          globalDispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: true });
          setNewSearch(false);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [searchText, resultsState?.ariaFilters]
  );

  const handleDidYouMeanTextClick = useCallback(
    (text: string) => {
      setSearchText(text);
      handleSearch(text);
    },
    [handleSearch]
  );

  const getAriaSearchSharePayload = () => {
    if (isReg360() || triggeredFromUserProfile) {
      const urlParams = new URLSearchParams(window.location.search);
      return decodeBase64ToObject(urlParams.get('ariaShare') ?? '');
    }

    return decodeBase64ToObject(payload);
  };

  const getAriaSharedSearch = () => {
    const searchPayload: any = getAriaSearchSharePayload();

    const aria = {
      sharedSearchText: null,
      sharedSearchId: null
    };

    const isItAriaShare =
      searchPayload?.ariaSearchTerm && searchPayload?.isAriaOpen && searchPayload?.ariaSearchId;

    if (isItAriaShare && !showDocumentsModal) {
      aria.sharedSearchText = searchPayload?.ariaSearchTerm;
      aria.sharedSearchId = searchPayload?.ariaSearchId;
      setSearchText(searchPayload?.ariaSearchTerm);
      setShowDocumentsModal(true);
    }

    return aria;
  };

  useEffect(() => {
    let totalDocumentCount = 0;
    let totalPagesCount = 0;
    if (!isCount) {
      Object.keys(resultsState.applicationResults).forEach((source: any) => {
        resultsState.applicationResults[source].results.forEach((result: any) => {
          totalDocumentCount += result.document_count;
          totalPagesCount += result.document_total_page_count;
        });
      });
    } else {
      totalDocumentCount = DocCount;
      totalPagesCount = PageCount;
    }
    setDocumentCount(totalDocumentCount);
    setPageCount(totalPagesCount);
  }, [DocCount, PageCount]);

  useEffect(() => {
    if (modalOpen && !applicationFilters) {
      setApplicationFilters(resultsState?.filters);
    }
  }, [modalOpen, resultsState.filters]);

  useEffect(() => {
    const { sharedSearchText, sharedSearchId } = getAriaSharedSearch();
    handleSearch(sharedSearchText, sharedSearchId);
  }, [resultsState.ariaFilters]);

  useEffect(() => {
    const selected = Object.entries(sources).map(([label, module]: any) => ({
      label,
      value: label,
      module: module.map((value: any) => ({ value }))
    }));
    setSelectedSources(selected);
  }, [sources]);

  const handleAriaShare = () => {
    const ariaSharePayload = {
      ariaSearchTerm: searchText,
      isAriaOpen: true,
      ariaSearchId: resultsState?.searchIds?.ariaSearchId || ''
    };

    if (isReg360() || triggeredFromUserProfile) {
      const encodedPayload: any = encodeObjectToBase64(ariaSharePayload);
      history.push({ search: `${window.location.search}&ariaShare=${encodedPayload}` });
      return;
    }

    const decodedPayload: any = decodeBase64ToObject(payload);

    const newPayload = {
      ...decodedPayload,
      ...ariaSharePayload
    };

    const encodedPayload: any = encodeObjectToBase64(newPayload);
    const previousEncodedPayload = window.location.pathname.split('/search/')[1];
    if (previousEncodedPayload !== encodedPayload) {
      const newPath = window.location.pathname.replace(previousEncodedPayload, encodedPayload);
      history.push(newPath);
    }
  };

  return (
    <Box>
      {!showDocumentsModal ? (
        <Drawer
          sx={{
            '& >.MuiDrawer-paper': {
              height: '60vh',
              borderRadius: '16px',
              paddingX: '24px',
              paddingY: '32px',
              position: 'absolute'
            }
          }}
          anchor='bottom'
          open={modalOpen}
          onClose={handleModalClose}>
          <Box display='flex' flexDirection='column'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'stretch'
              }}>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: 'center',
                  alignSelf: 'center',
                  fontWeight: '700',
                  fontSize: '20px',
                  color: 'gray.800',
                  textTransform: 'capitalize'
                }}>
                Ask RIA
              </Typography>
              <Tooltip title='Close'>
                <IconButton
                  onClick={handleModalClose}
                  sx={{ position: 'absolute', right: '24px', top: '26px' }}>
                  <Close />
                </IconButton>
              </Tooltip>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              <Divider
                sx={{
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: 'gray.400',
                  borderRadius: '2px',
                  mt: 1,
                  width: '64px'
                }}
              />
            </Box>
            <Box
              sx={{
                paddingTop: '64px',
                alignSelf: 'center',
                width: { xs: '762px', md: '762px', lg: '794px' },
                minWidth: '762px',
                maxWidth: '794px'
              }}>
              <Stack spacing={1}>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSearch();
                  }}>
                  <Box
                    sx={{
                      ...styles.searchBarAria,
                      borderBottomLeftRadius: sourceDropDownOpen ? 0 : 15
                    }}>
                    <Stack direction='row' alignItems='center'>
                      <Divider orientation='vertical' flexItem sx={{ marginY: 1.5 }} />
                      <Box flex={1}>
                        <SearchBarAutoComplete
                          id='cdp-search-input'
                          value={searchText}
                          label={
                            !(documentCount === 0 && pageCount === 0)
                              ? `Search here from ${documentCount} documents and ${pageCount} pages`
                              : 'Search Here'
                          }
                          options={searchSuggestions}
                          loading={isSuggestionsLoading}
                          onOpen={handleAutoCompleteOpen}
                          open={isSearchOpen}
                          onClose={handleAutoCompleteClose}
                          filterOptions={undefined}
                          onInputChange={handleKeyPress}
                          setSearch={value => setSearchText(value)}
                          clearSearchSuggestions={clearSearchSuggestions}
                          handleSearch={handleSearch}
                          disableSearch={isLoading}
                        />
                      </Box>
                      <Button
                        type='submit'
                        size='small'
                        startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                        sx={styles.searchButton}
                        disabled={isLoading}>
                        Search
                      </Button>
                    </Stack>
                  </Box>
                </form>
              </Stack>
            </Box>
            {isLoading && (
              <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                {' '}
                <CircularProgress color='success' />
              </Box>
            )}
          </Box>
        </Drawer>
      ) : (
        <Drawer
          sx={{
            '& >.MuiDrawer-paper': {
              height: '96vh',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              paddingX: '24px',
              paddingY: '24px',
              position: 'absolute',
              overflowY: 'hidden'
            }
          }}
          onClose={handleModalClose}
          anchor='bottom'
          open={showDocumentsModal}>
          <Box display='flex' flexDirection='column'>
            <Stack direction='row' justifyContent='space-between'>
              <Box sx={{ padding: '16px', width: '60%' }} display='flex' flexDirection='row'>
                <Box pr={2} alignSelf='center'>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: '20px',
                      color: 'gray.800',
                      textTransform: 'capitalize'
                    }}>
                    AskRIA
                  </Typography>
                </Box>
                <Box m='auto 0'>
                  <Divider orientation='vertical' sx={{ height: '20px', borderColor: '#E0E0E0' }} />
                </Box>
                <Box
                  pl={2}
                  sx={{
                    width: '80%'
                  }}>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      handleSearch();
                    }}>
                    <SearchBarAutoComplete
                      id='documents-modal'
                      value={searchText}
                      label={`Search here from ${documentCount} documents and ${pageCount} pages`}
                      options={searchSuggestions}
                      loading={isSuggestionsLoading}
                      onOpen={handleAutoCompleteOpen}
                      open={isSearchOpen}
                      onClose={handleAutoCompleteClose}
                      filterOptions={undefined}
                      onInputChange={handleKeyPress}
                      setSearch={value => handleTextChange(value)}
                      clearSearchSuggestions={clearSearchSuggestions}
                      handleSearch={handleSearch}
                      disableSearch={isLoading}
                      type='nav'
                      subType='aria'
                      handleClearSearch={handleClearSearch}
                      showRetainFilter
                      retainFilter={retainFilter}
                      onRetainFilterChange={onRetainFilterChange}
                      disableRetainFilter={disableRetainFilter}
                    />
                  </form>
                </Box>
              </Box>
              <Box m='auto 0'>
                <Tooltip title='Close'>
                  <IconButton onClick={handleModalClose}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
            <Divider
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray.200',
                mt: 1,
                marginX: '-24px'
              }}
            />
            <Box>
              {!isLoading ? (
                <DocumentsView
                  showSourceDropdown={false}
                  onDidYouMeanTextClick={handleDidYouMeanTextClick}
                  handleAriaShare={handleAriaShare}
                  renderedInPopup
                />
              ) : (
                <Loading />
              )}
            </Box>
            {!resultsState?.selectedDocument && (
              <NewFilters
                data={filters as Category[]}
                applyFilters={handleApplyFilters}
                isLoading={isLoading}
                selectedFilters={selectedFilters}
              />
            )}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default React.memo(AriaDrawer);
