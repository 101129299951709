import { Box, Typography } from '@mui/material';
import React from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { INDICATION_TOOLTIP_TEXT } from './constant';
import { indicationTooltipStyles } from './styles';

const IndicationTooltipInfo = ({ submissionData }: any) => {
  const formatText = (content: string) => {
    const convertedHtml = marked(content);
    const sanitizedHtml = DOMPurify.sanitize(convertedHtml, { USE_PROFILES: { html: true } });
    return sanitizedHtml;
  };

  const formatDescription = (description: string) => {
    // Regular expression to match section numbers and titles (e.g., "1.1 Unresectable or Metastatic Melanoma")
    // Step 1: Replace **text** with <strong>text</strong>
    let formattedDescription = description.replace(
      /\*\*(.*?)\*\*/g,
      '<span style="font-size: 12px; font-weight: 500;">$1</span>'
    );

    // Step 2: Bold section titles like "1.1 Title Here"
    formattedDescription = formattedDescription.replace(
      /^(\d+\.\d+ [^\n]+)/gm,
      '<p style="font-size: 12px; font-weight: 700;">$1</p>'
    );

    return formatText(formattedDescription);
  };
  return (
    <Box>
      <Typography sx={indicationTooltipStyles.infoText}>
        {INDICATION_TOOLTIP_TEXT.map((field: any) => {
          if (submissionData && field?.id in submissionData) {
            return (
              <li key={field?.id} style={{ marginBottom: 1 }}>
                <b style={{ fontWeight: 700 }}>{field?.label}: </b>
                {field?.id === 'indications_and_usage' ? (
                  <Typography
                    sx={indicationTooltipStyles.infoText}
                    dangerouslySetInnerHTML={{
                      __html: formatDescription(submissionData[field?.id])
                    }}
                  />
                ) : (
                  submissionData[field?.id]
                )}
              </li>
            );
          }
          return null;
        })}
      </Typography>
    </Box>
  );
};

export default React.memo(IndicationTooltipInfo);
