const NotificationFlagStyles = {
  infoIcon: {
    height: '12px',
    width: '12px',
    color: 'secondary.350'
  },
  flagIcon: { height: '12px', width: '18px' },
  sourceTitle: { fontWeight: 700 },
  sourceSubText: { fontWeight: 400, fontSize: '11px' },
  boxSpace: { height: '12px' },
  popoverContainer: { pointerEvents: 'none', '& .MuiPaper-root': { borderRadius: '12px' } },
  cardWrapper: { p: '12px' },
  searchSubscriptionFlagIcon: {
    textTransform: 'none',
    '& svg': { height: '12px', width: '18px', mr: '4px' },
    mt: '4px'
  }
};

export default NotificationFlagStyles;
