import typography from '../../../themev5/typography';

const notificationTypeMixin: { [key: string]: any } = {
  paddingY: 0.5,
  paddingX: 1,
  borderRadius: 1
};

const listContainer: { [key: string]: any } = {
  display: 'inline-block',
  width: '100%'
};

const styles = {
  container: {
    borderRadius: '15px',
    height: 'inherit',
    bgcolor: 'white',
    width: '400px'
  },
  listContainer,
  listContainerUnseen: {
    ...listContainer,
    backgroundColor: 'primary.background'
  },
  tabTitle: {
    fontSize: typography.subtitle2.fontSize,
    textTransform: 'none'
  },
  tabTitleActive: {
    fontSize: typography.subtitle2.fontSize,
    fontWeight: 'bold',
    textTransform: 'none'
  },

  greenText: {
    color: 'primary.main'
  },
  redText: {
    color: 'red.main'
  },
  orangeText: {
    color: 'orange.main'
  },
  notificationDate: {
    color: 'gray.lightVariant2',
    marginRight: 1
  },
  noDataText: {
    mt: 2,
    color: 'gray.lightVariant2',
    textTransform: 'none'
  },
  applicationNotification: {
    ...notificationTypeMixin,
    bgcolor: '#FFD1D1',
    color: '#D10F0F'
  },
  quickSearchNotification: {
    ...notificationTypeMixin,
    bgcolor: '#d9c7f5',
    color: '#400fd1'
  },
  advancedSearchNotification: {
    ...notificationTypeMixin,
    bgcolor: '#d9c7f5',
    color: '#400fd1'
  },
  notificationIcon: {
    width: 30,
    height: 30,
    fontSize: 10,
    backgroundColor: '#f0f0f0'
  },
  notificationTitleButton: {
    color: 'black.main',
    marginLeft: 1,
    '&:hover': {
      '& p': {
        textDecorationLine: 'underline',
        textDecorationColor: '#33B187'
      }
    }
  },
  subNotificationAccordion: {
    '&:before': { height: '0px' },
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'white.smoke'
    }
  },
  subNotificationAccordionSummary: {
    flexDirection: 'row-reverse',
    minHeight: 0,
    padding: 0
  },
  subNotificationBox: {
    backgroundColor: 'gray.background',
    p: 1,
    m: 1,
    borderRadius: '6px',
    overflow: 'hidden'
  },
  subNotificationClickableBox: {
    backgroundColor: 'gray.background',
    p: 1,
    m: 1,
    borderRadius: '6px',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'green', // Background color on hover
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)' // Box shadow on hover
    },
    cursor: 'pointer'
  }
};

export default styles;
