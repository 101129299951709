import { Box, Divider, Grid, Stack } from '@mui/material';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import notificationsStyles from './styles/Notifications.styles';
import NotificationDetails from './components/NotificationDetails';
import NotificationsList from './components/NotificationsList';
import { NotificationDataType } from './components/NotificationInterface';

// store
import NotificationsStore from '../../store/Notifications';
import NotificationsAction from '../../store/Notifications/actions';
import { INITIAL_SELECTED_NOTIFICATION } from './constants';
import HeaderTabs from '../UserProfile/components/HeaderTabs';
import { Header } from '../UserProfile/interface';

const NotificationDataDefault: NotificationDataType = {
  results: [],
  page: 1,
  hasMore: true
};
const NotificationView = () => {
  const location = useLocation();
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);

  const [tab, setTab] = React.useState('');
  const [allNotificationResults, setAllNotificationResults] =
    useState<NotificationDataType>(NotificationDataDefault);
  const [sourceNotificationResults, setSourceNotificationResults] =
    useState<NotificationDataType>(NotificationDataDefault);
  const [applicationNotificationResults, setApplicationNotificationResults] =
    useState<NotificationDataType>(NotificationDataDefault);
  const [searchNotificationResults, setSearchNotificationResults] =
    useState<NotificationDataType>(NotificationDataDefault);
  const [documentSearchNotificationResults, setDocumentSearchNotificationResults] =
    useState<NotificationDataType>(NotificationDataDefault);
  const tabList = [
    {
      label: 'All',
      value: 'all',
      component: (
        <NotificationsList
          key='notification-list-all'
          notificationData={allNotificationResults}
          setNotificationData={setAllNotificationResults}
          channelType=''
        />
      )
    },
    {
      label: 'Source',
      value: 'source',
      component: (
        <NotificationsList
          key='notification-list-data-source'
          notificationData={sourceNotificationResults}
          setNotificationData={setSourceNotificationResults}
          channelType='data_source'
        />
      )
    },
    {
      label: 'Application (Reg360)',
      value: 'applications',
      component: (
        <NotificationsList
          key='notification-list-application'
          notificationData={applicationNotificationResults}
          setNotificationData={setApplicationNotificationResults}
          channelType='application'
        />
      )
    },
    {
      label: 'Application Search',
      value: 'searches',
      component: (
        <NotificationsList
          key='notification-list-search'
          notificationData={searchNotificationResults}
          setNotificationData={setSearchNotificationResults}
          channelType='search'
        />
      )
    },
    {
      label: 'Document Search',
      value: 'document_searches',
      component: (
        <NotificationsList
          key='notification-list-document-search'
          notificationData={documentSearchNotificationResults}
          setNotificationData={setDocumentSearchNotificationResults}
          channelType='document_search'
        />
      )
    }
  ];

  // based on search params, set the selected tab using useEffect
  useEffect(() => {
    const urlTabValue = new URLSearchParams(location.search).get('tab') ?? 'all';
    if (urlTabValue) {
      setTab(urlTabValue);
    }
  }, [location]);
  return (
    <>
      <Grid item md={6}>
        <Box sx={notificationsStyles.notificationContainer}>
          <HeaderTabs
            header={tabList.map(tabVal => ({ key: tabVal.value, label: tabVal.label } as Header))}
            selectedTab={tab}
            setSelectedTab={setTab}
            handleTabClickAction={() => {
              notificationsDispatch({
                type: NotificationsAction.SET_SELECTED_NOTIFICATION,
                value: { ...INITIAL_SELECTED_NOTIFICATION }
              });
            }}
          />
          <Divider />
          {tabList.find(tabItem => tabItem.value === tab)?.component}
        </Box>
      </Grid>
      <Grid item md={4}>
        <Stack
          display={
            isEqual(notificationsState.selectedNotification, INITIAL_SELECTED_NOTIFICATION)
              ? 'none'
              : 'flex'
          }
          sx={notificationsStyles.notificationContainer}>
          <NotificationDetails />
        </Stack>
      </Grid>
    </>
  );
};
export default NotificationView;
