import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Stack, Tooltip } from '@mui/material';
import SearchTermBadge from './SearchTermBadge';
import Cards from './Cards';
import ExpandedDrawer from './ExpandedDrawer';
import styles, { horizontalTimelineStyle, indicationTooltipStyles, plusStyle } from './styles';
import ApprovalDateIcon from '../../../../assets/svgs/CardsPage/ApprovalDateIcon';
import IndicationTooltipInfo from './IndicationTooltipInfo';

const TimelineView = ({
  dataByYear,
  drugs,
  applicationNumbers,
  searchTermList,
  onUpdatePlacement,
  id,
  setScrollRefElement,
  enableDownload = false,
  isReport = false
}: any) => {
  const cardsRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(0.8);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState(false);
  const [activeBadge, setActiveBadge] = useState('');
  const [expandedPayload, setExpandedPayload] = useState({});

  const handleClose = () => {
    setIsExpandedCardOpen(false);
  };

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    if (!isDragging) return;

    const { scrollTop, scrollLeft } = scrollRef.current!;
    scrollRef.current!.scrollTop = scrollTop - (event.clientY - lastPosition.y);
    scrollRef.current!.scrollLeft = scrollLeft - (event.clientX - lastPosition.x);

    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleResize = () => {
    const widthtimline = centerRef?.current?.clientWidth || 0;
    const width = viewRef?.current?.clientWidth || 0;
    const clientwidth = widthtimline * Object.keys(dataByYear).length + (width + 30);

    const totalWidth = document.getElementById(id)?.offsetWidth ?? 0;
    const newScale = totalWidth / clientwidth;

    if (newScale >= 1) {
      setScale(0.8);
    } else {
      setScale(newScale);
    }
  };
  useEffect(() => {
    if (isReport) {
      handleResize();
    }
  }, [drugs, document.getElementById(id)?.offsetWidth]);

  useEffect(() => {
    if (enableDownload) {
      setScrollRefElement(scrollRef);
    }
  }, [scrollRef]);

  useEffect(() => {
    if (isReport) {
      const scrollElement = cardsRef?.current;
      if (!scrollElement) return;

      const clientHeight = scrollElement?.clientHeight || 0;
      const computedStyle = window.getComputedStyle(scrollElement);
      const marginBottomValue = parseFloat(computedStyle?.marginBottom) || 0;

      // Calculate the total height considering scale and application numbers
      const totalHeight = (clientHeight + marginBottomValue) * scale * drugs.length + 50;
      onUpdatePlacement?.(Math.ceil(totalHeight / 10) + 1);
    }
  }, [drugs, scale]);

  return (
    // @ts-ignore
    <Stack
      direction='row'
      width={isReport ? '100%' : 'auto'}
      height='100%'
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      sx={{
        ...horizontalTimelineStyle,
        '&>div': {
          transform: isReport ? `scale(${scale})` : null,
          transformOrigin: 'left top',
          transition: 'transform 0.3s ease-in-out',
          minWidth: isReport ? `${scale * 200}px` : '180px'
        }
      }}>
      <Stack sx={styles.timeLineCards} ref={viewRef} height='100%'>
        {drugs.length !== 0 &&
          drugs?.map((drug: any) => {
            return (
              <Cards
                key={drug?.identifier}
                tradeName={drug?.title}
                applicationType={drug?.labels ? drug?.labels[0] : drug?.colorScheme}
                cardsRef={cardsRef}
              />
            );
          })}
      </Stack>
      <Stack flexDirection='row' height='100%'>
        {Object.entries(dataByYear)
          .sort(([yearA], [yearB]) => {
            return parseInt(yearA, 10) - parseInt(yearB, 10);
          })
          // eslint-disable-next-line no-unused-vars
          .map(([year, data]: any, _) => {
            return (
              <Stack
                direction='column'
                alignItems='center'
                key={year}
                minWidth={isReport ? 'auto' : '115px !important'}
                id={`year-${year}`}
                ref={centerRef}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  width='100%'
                  height={24}
                  sx={{
                    ...styles.yearBox,
                    position: isReport ? 'relative' : 'sticky'
                  }}>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 600, color: 'gray.600', fontSize: '14px' }}
                      variant='caption'>
                      {year === '1900' ? 'NA' : year}
                    </Typography>
                  </Box>
                </Stack>
                <Stack display='flex' justifyContent='center' flexDirection='column' width='100%'>
                  {applicationNumbers.map((item: string) => {
                    if (item in data.searchterm && item in data.approvalDate) {
                      return (
                        <SearchTermBadge
                          key={item}
                          application={data?.searchterm[item]}
                          year={year}
                          searchTermList={searchTermList}
                          activeBadge={activeBadge}
                          isExpandedCardOpen={isExpandedCardOpen}
                          setIsExpandedCardOpen={setIsExpandedCardOpen}
                          setActiveBadge={setActiveBadge}
                          setExpandedPayload={setExpandedPayload}
                          showApprovalDate
                        />
                      );
                    }
                    if (item in data.searchterm) {
                      return (
                        <SearchTermBadge
                          key={item}
                          application={data?.searchterm[item]}
                          year={year}
                          searchTermList={searchTermList}
                          activeBadge={activeBadge}
                          isExpandedCardOpen={isExpandedCardOpen}
                          setIsExpandedCardOpen={setIsExpandedCardOpen}
                          setActiveBadge={setActiveBadge}
                          setExpandedPayload={setExpandedPayload}
                          showApprovalDate={false}
                        />
                      );
                    }
                    if (item in data.approvalDate) {
                      return (
                        <Box className='plus' sx={plusStyle} key={item}>
                          <Tooltip
                            title={
                              <IndicationTooltipInfo submissionData={data?.approvalDate[item]} />
                            }
                            componentsProps={{
                              tooltip: {
                                sx: indicationTooltipStyles.tooltipInfo
                              }
                            }}>
                            <Box sx={styles.badgeContainer}>
                              <ApprovalDateIcon />
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    }
                    return (
                      <Box key={item} className='eplus' sx={plusStyle}>
                        <Box
                          sx={{
                            zIndex: 1,
                            position: 'relative'
                          }}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>
      <ExpandedDrawer
        open={isExpandedCardOpen}
        payload={{
          ...expandedPayload
        }}
        onClose={handleClose}
      />
    </Stack>
  );
};
export default React.memo(TimelineView);
