import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { CloseCrossIcon, OpenLinkIcon } from '../../assets/svgs/Icons';

import PDFViewer from '../PDFViewer';

import { styles } from './PdfPreview.styles';
import trimText from '../../utils/trimText';
import LanguageToggleButton from '../LanguageToggleComponents/LanguageToggleButton';
import LanguageToggleText from '../LanguageToggleComponents/LanguageToggleText';
import AriaDocumentMeta from '../ChatRIA/component/AriaDocumentMeta';

const PDFCard = ({ viewType, data, title }: any) => {
  if (!isEmpty(data) && viewType === 'document_search')
    return (
      <AriaDocumentMeta
        item={data?.modifiedAriaResult?.item}
        source={data?.modifiedAriaResult?.sourceName || 'us'}
      />
    );
  return (
    title && (
      <Tooltip title={title}>
        <Typography sx={styles.titleText}>{trimText(title ?? '', 40)}</Typography>
      </Tooltip>
    )
  );
};

const PDFPreview = ({
  open,
  onClose,
  pdfUrl,
  handleChatRia,
  title = '',
  setOriginalTextEnabled,
  originalTextEnabled,
  currentFileOriginalLanguage,
  source,
  pdfLoading,
  hideChatRia,
  reg360Text,
  onReg360Click,
  initialPage,
  phraseToHighlight,
  keywordsToHighlight,
  viewType,
  selectedNotification
}: any) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={open && Boolean(pdfUrl)}
      fullWidth
      maxWidth='lg'
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px'
        }
      }}>
      <Box sx={styles.close} display='flex' alignItems='center'>
        <Stack
          flex='display'
          alignItems='center'
          sx={{ pl: '15px', flexDirection: 'row', maxWidth: '80vw' }}>
          <PDFCard viewType={viewType} data={selectedNotification} title={title} />
          {!hideChatRia && (
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ p: '5px', height: originalTextEnabled ? 40 : 16 }}
            />
          )}
          {!hideChatRia && (
            <Tooltip title='Use Chat RIA to interact with the document'>
              <Button
                variant='text'
                disabled={!pdfUrl || pdfLoading}
                onClick={() =>
                  handleChatRia({
                    pdfUrl
                  })
                }
                sx={styles.chatRiaButton}>
                Chat RIA
                <Send sx={styles.chatRiaIcon} />
              </Button>
            </Tooltip>
          )}
          {source === 'jp' && (
            <>
              <Divider
                orientation='vertical'
                variant='middle'
                flexItem
                sx={{ p: '5px', height: originalTextEnabled ? 40 : 16 }}
              />{' '}
              <Box display='flex' flexDirection='column' ml='10px'>
                <Box sx={{ justify: 'center', alignItems: 'center' }}>
                  {!isEmpty(currentFileOriginalLanguage) && (
                    <LanguageToggleButton
                      source={source}
                      originalTextEnabled={originalTextEnabled}
                      toggleTextLanugage={() => {
                        setOriginalTextEnabled(!originalTextEnabled);
                      }}
                      color='green'
                    />
                  )}
                </Box>
                <Box>
                  {originalTextEnabled && <LanguageToggleText styles={{ textAlign: 'start' }} />}
                </Box>
              </Box>
            </>
          )}
        </Stack>
        <Stack direction='row' alignItems='center' spacing='5px'>
          {reg360Text && (
            <Button variant='text' onClick={onReg360Click} sx={styles.reg360Button}>
              {reg360Text}
            </Button>
          )}
          {reg360Text && (
            <Divider
              orientation='vertical'
              variant='middle'
              sx={{ height: originalTextEnabled ? 40 : 16 }}
            />
          )}
          <Tooltip title='Open in new tab'>
            <IconButton target='_blank' href={pdfUrl}>
              <OpenLinkIcon sx={styles.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Close'>
            <IconButton onClick={onClose}>
              <CloseCrossIcon sx={styles.icon} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
      {pdfLoading && (
        <Box sx={styles.iframeContainer}>
          <Typography>Loading...</Typography>
        </Box>
      )}
      {!pdfLoading &&
        (pdfUrl ? (
          <Box sx={styles.iframeContainer}>
            <PDFViewer
              fileUrl={pdfUrl}
              defaultScale={1.35}
              initialPage={initialPage}
              phraseToHighlight={phraseToHighlight}
              keywordsToHighlight={keywordsToHighlight}
            />
          </Box>
        ) : null)}
    </Dialog>
  );
};

PDFPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  handleChatRia: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  setOriginalTextEnabled: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  originalTextEnabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  currentFileOriginalLanguage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  source: PropTypes.string,
  pdfLoading: PropTypes.bool,
  hideChatRia: PropTypes.bool,
  reg360Text: PropTypes.string,
  onReg360Click: PropTypes.func,
  initialPage: PropTypes.number,
  phraseToHighlight: PropTypes.string,
  keywordsToHighlight: PropTypes.arrayOf(PropTypes.string)
};

PDFPreview.defaultProps = {
  pdfLoading: false,
  hideChatRia: false,
  reg360Text: null,
  onReg360Click: null,
  initialPage: 0,
  phraseToHighlight: null,
  keywordsToHighlight: []
};

export default PDFPreview;
